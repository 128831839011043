import React, { useState } from "react";
import images from "../../../Constants/images";
import TopHeadingSection from "../../Common/Headings/TopHeadingSection";
import Product1 from "./Product1";
import Product2 from "./Product2";
import Product3 from "./Product3";
import Product4 from "./Product4";
import ProductOverlay from "./ProductOverlay";
import { Fade } from "react-reveal";

function ProductSection({ paddingBottom, product }) {
  const [seeMore, setSeeMore] = useState(false);
  const [paragraphOne, setParagraphOne] = useState("");
  const [paragraphTwo, setParagraphTwo] = useState("");
  const [heading, setHeading] = useState("");

  return (
    <>
      <ProductOverlay
        seeMore={seeMore}
        setSeeMore={setSeeMore}
        paragraphOne={paragraphOne}
        paragraphTwo={paragraphTwo}
        heading={heading}
      />
      <section
        style={{ paddingBottom: paddingBottom }}
        className="product__padding"
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <TopHeadingSection
                mainHeading="Our Products"
                description="At Himont Group, our enduring dedication to personalized healthcare, exceptional pharmaceutical quality, and customer-centric innovation drives us to deliver a wide range of cutting-edge medicines and products. Leveraging our combined strengths and expertise, we address diverse health needs through our Pharmaceuticals, Herbal, Derma, and Consumer product lines, consistently aiming for elevated excellence in healthcare solutions."
              />
            </div>
          </div>
          <div className="product-spacing"></div>
          <Fade bottom>
            <div className="row product-box-section">
              <Product1
                url={product[0]?.data?.product_img_1?.url}
                alt={product[0]?.data?.product_img_1?.alt}
                setSeeMore={setSeeMore}
                setParagraphOne={setParagraphOne}
                setParagraphTwo={setParagraphTwo}
                setHeading={setHeading}
              />
            </div>
          </Fade>
          <Fade bottom>
            <div className="row product-box-section column-reverse-section">
              <Product2
                url={product[0]?.data?.product_img_2?.url}
                alt={product[0]?.data?.product_img_2?.alt}
                setSeeMore={setSeeMore}
                setParagraphOne={setParagraphOne}
                setParagraphTwo={setParagraphTwo}
                setHeading={setHeading}
              />
            </div>
          </Fade>
          <Fade bottom>
            <div className="row product-box-section">
              <Product3
                url={product[0]?.data?.product_img_3?.url}
                alt={product[0]?.data?.product_img_3?.alt}
                setSeeMore={setSeeMore}
                setParagraphOne={setParagraphOne}
                setParagraphTwo={setParagraphTwo}
                setHeading={setHeading}
              />
            </div>
          </Fade>
          <Fade bottom>
            <div className="row product-box-section column-reverse-section">
              <Product4
                url={product[0]?.data?.product_img_4?.url}
                alt={product[0]?.data?.product_img_4?.alt}
                setSeeMore={setSeeMore}
                setParagraphOne={setParagraphOne}
                setParagraphTwo={setParagraphTwo}
                setHeading={setHeading}
              />
            </div>
          </Fade>
        </div>
      </section>
    </>
  );
}

export default ProductSection;
