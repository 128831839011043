import React from "react";
import images from "../../Constants/images";
import { useAllPrismicDocumentsByType } from "@prismicio/react";
import TopHeadingSection from "../Common/Headings/TopHeadingSection";

function Team() {
  //
  //
  //

  const [data] = useAllPrismicDocumentsByType("team");

  const loremTextVisible = window.location.pathname.includes("/about");

  return (
    <section className="team-section-spacing">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <TopHeadingSection
              mainHeading="Our Executive team"
              description="Our company's strength lies in fostering a collaborative environment where each team member's talent can thrive and individuality is celebrated. Recognizing our team as our most valuable asset, we cultivate a culture of openness and ease, empowering employees to express themselves and contribute to our collective vision and success."
            />
          </div>
          <div className="container exec-team">
            <div className="row">
              {data?.map((i, index) => (
                <div className="col-lg-6" key={index}>
                  <div className="adjust-box">
                    <div className="round-box">
                      <img
                        className="dir-img"
                        src={i?.data?.image?.url}
                        alt={i?.data?.image?.alt}
                        loading="lazy"
                      />
                    </div>

                    <div className="text-align">
                      <h5 className="dir-name">{i?.data?.name[0]?.text}</h5>
                      <h6 className="dir-designation">
                        {i?.data?.designation[0]?.text}
                      </h6>

                      {loremTextVisible && (
                        <p className="dir-paragraph">
                          {i?.data?.description[0]?.text}
                        </p>
                      )}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Team;
