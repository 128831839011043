import React from "react";
import TopHeadingSection from "../Common/Headings/TopHeadingSection";

import { Fade } from "react-reveal";

function Operations({ global }) {
  return (
    <section className="operations-section">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <TopHeadingSection
              subText="Our Operations"
              mainHeading="Current Operations"
              description="Himont is currently operating in these countries."
            />
          </div>
        </div>
        <Fade bottom>
          <div className="row">
            <div className="col-lg-2">
              <div className="countries">
                <img
                  src={global[0]?.data.pakistan?.url}
                  alt="pakistan"
                  loading="lazy"
                />
                <p className="country-name">Pakistan</p>
              </div>
            </div>
            <div className="col-lg-2">
              <div className="countries">
                <img
                  src={global[0]?.data.afghanistan?.url}
                  alt="afganistan"
                  loading="lazy"
                />
                <p className="country-name">Afghanistan</p>
              </div>
            </div>
            <div className="col-lg-2">
              <div className="countries">
                <img
                  src={global[0]?.data.ivorycoast?.url}
                  alt="ivory"
                  loading="lazy"
                />
                <p className="country-name">Ivory Coast</p>
              </div>
            </div>
            <div className="col-lg-2">
              <div className="countries">
                <img
                  src={global[0]?.data.philippine?.url}
                  alt="philippine"
                  loading="lazy"
                />

                <p className="country-name">Philippine</p>
              </div>
            </div>
            <div className="col-lg-2">
              <div className="countries">
                <img
                  src={global[0]?.data.yemen?.url}
                  alt="yemen"
                  loading="lazy"
                />
                <p className="country-name">Yemen</p>
              </div>
            </div>
            <div className="col-lg-2">
              <div className="countries">
                <img
                  src={global[0]?.data.kenya?.url}
                  alt="kenya"
                  loading="lazy"
                />
                <p className="country-name">Kenya</p>
              </div>
            </div>
          </div>
        </Fade>
      </div>
    </section>
  );
}

export default Operations;
