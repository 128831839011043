import * as prismic from "@prismicio/client";
export const repositoryName = "himont";

export const client = prismic.createClient(repositoryName, {
  accessToken: "",

  // routes: [
  //   {
  //     type: "projects",
  //     path: "/projects",
  //   },
  // ],
});
