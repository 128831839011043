import React from "react";
import Fade from "react-reveal";

function Product4({
  url,
  alt,
  setSeeMore,
  setParagraphOne,
  setParagraphTwo,
  setHeading,
}) {
  const openLink = () => {
    window.open(
      "https://himont.com/shop/product-category/consumer-products/",
      "_blank"
    );
  };
  return (
    <>
      <div className="col-lg-5 product-center">
        <div className="product-info-box herbal-border left-product-info">
          <h2 className="product-heading">Consumer Products</h2>
          <p className="base-paragraph product-paragraph">
            Inspired by international confectionery companies, their SOP and
            GMP, we wish to challenge the already established names by bringing
            innovation and change into the FMCG.
          </p>
          <p className="base-paragraph product-paragraph hide-paragraph">
            We have established a facility that is set on international
            standards and the products we make are now being exported to various
            countries in the world. Top-quality Drinks and confectionery items
            are set to the highest standards possible and contain Vitamin A and
            Vitamin C for an instant energy boost. The focus of the new consumer
            division at Himont is to stay true to our commitment to Health and
            Quality.
          </p>
          <div
            onClick={() => {
              setSeeMore(true);
              setParagraphOne(`Inspired by international confectionery companies, their SOP and
              GMP, we wish to challenge the already established names by bringing
              innovation and change into the FMCG.`);
              setParagraphTwo(`We have established a facility that is set on international
              standards and the products we make are now being exported to various
              countries in the world. Top-quality Drinks and confectionery items
              are set to the highest standards possible and contain Vitamin A and
              Vitamin C for an instant energy boost. The focus of the new consumer
              division at Himont is to stay true to our commitment to Health and
              Quality.`);
              setHeading(`Consumer Products`);
            }}
            className="product-see-more"
          >
            Read More
          </div>
          <button className="view-product-btn" onClick={openLink}>
            View Consumer Products
          </button>
        </div>
      </div>

      <div className="col-lg-7">
        <div className="product-section herbal-bg">
          <div className="circle-lg light-herbal-bg circle-lg-left"></div>
          <div className="circle-box light-herbal-bg">
            <img className="product-img" src={url} alt={alt} loading="lazy" />
          </div>

          <div className="circle-sm light-herbal-bg circle-sm-left"></div>
        </div>
      </div>
    </>
  );
}

export default Product4;
