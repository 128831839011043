import React from "react";
import Paragraphs from "../Common/Headings/Paragraphs";
import TopHeadingSection from "../Common/Headings/TopHeadingSection";
import images from "../../Constants/images";
import Fade from "react-reveal";

function Pharma({ csr }) {
  return (
    <section id="Pharmacovigilance">
      <Fade>
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <TopHeadingSection
                subText="A brief look"
                mainHeading="Pharmacovigilance"
              />
            </div>
          </div>
          <div className="row pharma-section">
            <div className="col-lg-12 qm-align-section">
              {/* <div className="pharma-container">
                <img
                  src={csr[0]?.data?.pharma_img1?.url}
                  alt={csr[0]?.data?.pharma_img1?.alt}
                  className="community-pharma-img"
                  loading="lazy"
                />
              </div> */}

              <h3 className="qm-section-heading">What is Pharmacovigilance?</h3>
              <Paragraphs
                nameOfClass="qm-justify-text"
                paragraphText="Medicines and vaccines have transformed the prevention and treatment of diseases. In addition to their benefits, medicinal products may also have side effects, some of which may be undesirable and / or unexpected. Pharmacovigilance is the science and activities relating to the detection, assessment, understanding and prevention of adverse effects or any other medicine/vaccine related problem."
              />
              <Paragraphs
                nameOfClass="qm-justify-text"
                paragraphText="All medicines and vaccines undergo rigorous testing for safety and efficacy through clinical trials before they are authorized for use. However, the clinical trial process involves studying these products in a relatively small number of selected individuals for a short period of time. Certain side effects may only emerge once these products have been used by a heterogenous population, including people with other concurrent diseases, and over a long period of time."
              />
            </div>
          </div>
        </div>
      </Fade>
      <Fade bottom>
        <div className="container">
          <div className="row pharma-section">
            <div className="col-lg-12 qm-align-section">
              {/* <div className="pharma-container">
                <img
                  src={csr[0]?.data?.pharma_img2?.url}
                  alt={csr[0]?.data?.pharma_img2?.alt}
                  className="community-pharma-img"
                  loading="lazy"
                />
              </div> */}
              <h3 className="qm-section-heading">
                Himont's approach towards Pharmacovigilance
              </h3>
              <Paragraphs
                nameOfClass="qm-justify-text"
                paragraphText="Ensuring drug safety is of utmost importance to Himont as it helps in preventing more harm from the drug to the body than the disease itself. Timely identification of safety information is crucial to avoid uncertainties. Himont employs a comprehensive approach to pharmacovigilance, emphasizing quality safety information throughout product life cycles. Our pharmacovigilance experts, with extensive medical backgrounds, constantly monitor product compliance under national regulations."
              />
              <Paragraphs
                nameOfClass="qm-justify-text"
                paragraphText="Our mission is to enable healthy, happy lives by providing global healthcare solutions. We optimize the safe use of Himont’s products by evaluating benefits and risks and implementing risk management strategies alongside our strong safety governance framework. Collecting and analyzing safety data, we address any adverse effects reported by healthcare practitioners or patients, contributing to an improved understanding of product safety profiles."
              />
              <Paragraphs
                nameOfClass="pharma-download-text"
                paragraphText="Download Drug Pharmacovigilance Guidelines Now"
              />
              <a
                className="main-btn pharma-download-btn"
                href={images.guidelinesDoc}
                download="Drug Pharmacovigilance Guidelines"
                target="_blank"
              >
                Download Guidelines
              </a>
            </div>
          </div>
        </div>
      </Fade>
    </section>
  );
}

export default Pharma;
