import React, { useState } from "react";
import images from "../../Constants/images";
import CEOOverlay from "./CEOOverlay";
import { Fade } from "react-reveal";

function HeadSection({ home, setOverlay, overlay }) {
  const setBodyScroll = () => {
    document.body.style.overflow = "hidden";
  };

  return (
    <section className="background-head">
      <div className="container-fluid">
        <Fade bottom>
          <div className="row">
            <div className="col-lg-1"></div>
            <div className="col-lg-4 center">
              <img
                className="head-img"
                src={home[0]?.data?.ceo_image?.url}
                alt="head-img"
                loading="lazy"
                width="100%"
              />
            </div>

            <div className="col-lg-6 head-center">
              <p className="head-heading1">Intesar A. Siddique</p>
              <p className="head-heading2">President and CEO</p>
              <p className={`head-paragraph`}>
                With 30 years of excellence, Himont Group has established itself
                as a renowned Pharmaceutical and Herbal company. Leveraging our
                rich experience, we're now expanding into the FMCG sector,
                backed by our solid foundation in Pharmaceutical and Herbal
                businesses.
                <button
                  className={`span-text`}
                  onClick={() => {
                    setOverlay(true);
                    setBodyScroll();
                  }}
                >
                  {overlay === true ? "See Less" : "See More"}
                </button>
              </p>
            </div>
            <div className="col-lg-1"></div>
          </div>
        </Fade>
      </div>
    </section>
  );
}

export default HeadSection;
