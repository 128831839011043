import React from "react";
import Paragraphs from "../Common/Headings/Paragraphs";
import Fade from "react-reveal";

function CSRBox({
  csr,
  reverse,
  imageURL,
  imageALt,
  curvedLine,
  description,
  heading,
}) {
  return (
    <div className={`row ${reverse ? "departments-reverse" : ""}`}>
      <div className="col-lg-6 c-main-center">
        <div className="position-relative">
          <img width="100%" src={imageURL} alt={imageALt} loading="lazy" />
        </div>
      </div>

      <div className="col-lg-6">
        <div className="community-detail">
          <div className="community-arrow">
            {curvedLine === true ? (
              <svg
                className="reverse community-curved"
                width="104"
                height="68"
                viewBox="0 0 104 68"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M100.015 66.2072C99.897 67.0271 100.466 67.7878 101.286 67.9062C102.105 68.0246 102.866 67.456 102.985 66.636L100.015 66.2072ZM0.777447 12.5523C0.297447 13.2275 0.455686 14.164 1.13088 14.644L12.1339 22.4661C12.8091 22.9461 13.7455 22.7878 14.2255 22.1126C14.7055 21.4374 14.5473 20.5009 13.8721 20.021L4.09167 13.068L11.0446 3.28759C11.5246 2.61239 11.3664 1.67592 10.6912 1.19592C10.016 0.715921 9.0795 0.87416 8.5995 1.54936L0.777447 12.5523ZM102.985 66.636C104.638 55.1877 103.67 45.2057 100.056 36.8723C96.4333 28.5204 90.2 21.9361 81.528 17.2295C64.2655 7.86053 37.3494 5.92709 1.75008 11.9424L2.24992 14.9005C37.6506 8.91872 63.7345 10.9857 80.097 19.8662C88.2375 24.2844 93.9729 30.3871 97.3036 38.0661C100.642 45.7634 101.612 55.1558 100.015 66.2072L102.985 66.636Z"
                  fill="#CB343B"
                />
              </svg>
            ) : (
              <svg
                className="reverse community-curve-invert"
                width="104"
                height="68"
                viewBox="0 0 104 68"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M3.98459 66.2072C4.10303 67.0271 3.53436 67.7878 2.71445 67.9062C1.89452 68.0246 1.13384 67.456 1.01541 66.636L3.98459 66.2072ZM103.223 12.5523C103.703 13.2275 103.544 14.164 102.869 14.644L91.8661 22.4661C91.1909 22.9461 90.2545 22.7878 89.7745 22.1126C89.2945 21.4374 89.4527 20.5009 90.1279 20.021L99.9083 13.068L92.9554 3.28759C92.4754 2.61239 92.6336 1.67592 93.3088 1.19592C93.984 0.715921 94.9205 0.87416 95.4005 1.54936L103.223 12.5523ZM1.01541 66.636C-0.63826 55.1877 0.329681 45.2057 3.94418 36.8723C7.56668 28.5204 13.8 21.9361 22.472 17.2295C39.7345 7.86053 66.6506 5.92709 102.25 11.9424L101.75 14.9005C66.3494 8.91872 40.2655 10.9857 23.903 19.8662C15.7625 24.2844 10.0271 30.3871 6.69644 38.0661C3.35782 45.7634 2.38826 55.1558 3.98459 66.2072L1.01541 66.636Z"
                  fill="#CB343B"
                />
              </svg>
            )}
          </div>
          <h3 className="community-h1">{heading}</h3>
          <Paragraphs
            paragraphText={description}
            nameOfClass="community-paragraph"
          />
        </div>
      </div>
    </div>
  );
}
export default CSRBox;
