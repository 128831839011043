import React from "react";
import images from "../../Constants/images";
import Fade from "react-reveal";
import Paragraphs from "../Common/Headings/Paragraphs";

function ProductsHero({ product }) {
  return (
    <section className="event-bg">
      <div className="container">
        <div className="row">
          <div className="col-lg-8 offset-lg-2">
            <Fade bottom>
              <div className="text-center">
                <div style={{ position: "relative" }}>
                  <h1 className="main-heading">Our Products.</h1>
                  <svg
                    className="our-products-svg"
                    width="358"
                    height="19"
                    viewBox="0 0 358 19"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      opacity="0.5"
                      d="M5 14C99.4571 8.35706 301.297 -0.439291 353 9.51884"
                      stroke="#CB343B"
                      stroke-width="10"
                      stroke-linecap="round"
                    />
                  </svg>
                </div>
                <Paragraphs
                  nameOfClass="our-products-paragraph"
                  paragraphText="At Himont, we're dedicated to making a difference in people's
                  lives by developing innovative healthcare solutions."
                />
              </div>
            </Fade>
          </div>
        </div>
      </div>
      <div className="container-fluid">
        <div className="row">
          <Fade bottom>
            <div className="col-lg-12">
              <img
                className="our-products-page-img"
                src={product[0]?.data?.product_banner?.url}
                alt="products-image"
                loading="lazy"
              />
            </div>
          </Fade>
        </div>
      </div>
    </section>
  );
}

export default ProductsHero;
