import React from "react";

function Box({ about }) {
  return (
    <>
      <div className="col-lg-12">
        <div className="row">
          <div className="col-lg-6 box-end">
            <div className="box-detail box-margin">
              <img
                className="box-img"
                src={about[0]?.data?.mission_1?.url}
                alt={about[0]?.data?.mission_1?.alt}
                loading="lazy"
              />
              <h4 className="mission-heading3">
                Accessibility and Affordability
              </h4>
            </div>
          </div>
          <div className="col-lg-6 box-start">
            <div className="box-detail">
              <img
                className="box-img"
                src={about[0]?.data?.mission_2?.url}
                alt={about[0]?.data?.mission_2?.alt}
                loading="lazy"
              />
              <h4 className="mission-heading3">
                Name of reliability and quality
              </h4>
            </div>
          </div>
          <div className="col-lg-6 box-end">
            <div className="box-detail box-margin">
              <img
                className="box-img"
                src={about[0]?.data?.mission_3?.url}
                alt={about[0]?.data?.mission_3?.alt}
                loading="lazy"
              />
              <h4 className="mission-heading3">
                Continuously invest in Human Resource Development
              </h4>
            </div>
          </div>
          <div className="col-lg-6 box-start">
            <div className="box-detail">
              <img
                className="box-img"
                src={about[0]?.data?.mission_4?.url}
                alt={about[0]?.data?.mission_4?.alt}
                loading="lazy"
              />
              <h4 className="mission-heading3">
                Acquiring top notch R&D quality technology
              </h4>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Box;
