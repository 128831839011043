import React, { useEffect } from "react";
import Hero from "./Hero";
import images from "../../Constants/images";
import Description from "./Description";
import Community from "./Community";
import Footer from "../Common/Footer/Footer";
import { useAllPrismicDocumentsByType } from "@prismicio/react";
import Loader from "../Common/Loader";

function Research() {
  const [research] = useAllPrismicDocumentsByType("research");

  useEffect(() => {
    document.title = "Research and Development | Himont";
  }, []);
  return (
    <>
      {research === undefined ? (
        <Loader />
      ) : (
        <>
          <Hero research={research} />
          <Description />
          <Community research={research} />
          <Footer />
        </>
      )}
    </>
  );
}

export default Research;
