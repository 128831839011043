import React from "react";
import images from "../../Constants/images";

function Loader() {
  return (
    <section className="loader-section">
      <div className="loader-2">
        <img src={images.HG} alt="loader" />
        <div className="loader"></div>
      </div>
    </section>
  );
}

export default Loader;
