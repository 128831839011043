import React from "react";
import images from "../../Constants/images";
import ResearchItem from "./ResearchItem";
import { Fade } from "react-reveal";
function Community({ research }) {
  return (
    <section className="research-text-section">
      <div className="container">
        <div className="row">
          <Fade bottom>
            <ResearchItem
              imageURL={research[0]?.data?.community_research?.url}
              points={false}
              reverse={false}
              imageAlt="Academics"
              researchName="Himont's approach to R&D"
              researchParagraph="Healthcare is evolving at an unprecedented speed, and our unwavering focus on patients is driving us towards our goal of delivering the right treatments to the right patients more quickly than ever. In R&D, we're cultivating an environment that champions scientific innovation by fostering curiosity, embracing data and technology, collaborating across diverse teams, and continuously learning from our patients. Together, we're on a mission to transform healthcare and make a lasting impact on people's lives."
              pointsData={""}
            />
          </Fade>
          <Fade bottom>
            <ResearchItem
              pointsData={""}
              points={false}
              reverse={true}
              imageURL={research[0]?.data?.community_production?.url}
              imageAlt="Reasearch"
              researchName="Production"
              researchParagraph="From the very first day of inception there has always been one
            goal in mind, to be the best possible manufacturer of
            healthcare products in the country ensuring high quality
            standards and innovation. This is the same ethos we want to
            install in our Herbal, consumer and food products as well.
            High Standards ensure the exact same product and quality every
            time and keeping that in mind the Himont manufacturing
            facility has been designed and is manufacturing its products
            according to the Drug Regulatory Authority Pakistan. Our
            quality starts in the mind of the employee who is trained with
            the precise knowledge of what defines quality in his/her
            actions with in the manufacturing facility. The correct know
            how to execute the job at hand without compromising on the
            international guidelines for the standard processes, requires
            an investment of time and education for our employees that we
            are enthusiastic to provide. We strive to make this a reality
            through the use of International General Manufacturing
            Practices and International Standard Operating Procedures."
            />
          </Fade>
          <Fade bottom>
            <ResearchItem
              points={false}
              reverse={false}
              imageURL={research[0]?.data?.community_qa?.url}
              imageAlt="Simple"
              researchName="Quality Assurance"
              researchParagraph="The Quality of an organization is not just reflected in its
            products and procedures but in the state of mind of each
            employee and the systems which they follow, to achieve their
            work objectives. Quality is a continuous process which has to
            be upgraded, depending on the changing demands of the
            consumers, the environment and the social responsibilities and
            the direct result of high intentions, sincere efforts,
            intelligent direction and skillful execution. For this purpose
            Himont quality assurance unit tests each batch of raw material
            against the international standards and also checks the
            integrity of packaging materials. It is the ultimate
            responsibility of the quality assurance unit to ensure that
            each product falls well within the required standards that
            meet the expectations of physicians and patients to help
            maintain the optimal state of health and wellbeing."
              pointsData={""}
            />
          </Fade>
          <Fade bottom>
            <ResearchItem
              points={true}
              reverse={true}
              imageURL={research[0]?.data?.community_sop?.url}
              imageAlt="Hospital"
              researchName="SOP for Enlistment of Suppliers"
              researchParagraph=""
              pointsData={[
                "Himont selects suppliers with good reputation in market. Suppliers with experience of working with MNCs are preferred.",
                "Suppliers are scrutinized on number of parameters which include their organization structure, quality control systems, production flow, customer portfolio, certifications, production facilities, R&D etc.",
                "On successful evaluation, multiple raw material samples are obtained from different batches to make sure that quality does not vary.",
                `Materials are then imported for one batch and stability
              and overall goodness of material while manufacturing is
              observed. If requirements of quality are met then bulk
              orders are made.`,
                `GMP certificate for suppliers providing pharmaceuticals
              raw materials is mandatory to qualify for standards.
              Suppliers having WHO, US FDA certifications are preferred.`,
              ]}
            />
          </Fade>
        </div>
      </div>
    </section>
  );
}

export default Community;
