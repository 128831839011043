import React from "react";
import Paragraphs from "../Common/Headings/Paragraphs";

function SectorsBox({
  reverse,
  imageURL,
  imageAlt,
  iconURL,
  iconAlt,
  departmentName,
  departmentParagraph,
}) {
  return (
    <div className="sector-backshadow">
      <div className={`row ${reverse ? "departments-reverse" : ""}`}>
        <div className="col-lg-6 c-main-center">
          <div style={{ position: "relative" }}>
            <img
              style={{ width: "100%" }}
              src={imageURL}
              alt={imageAlt}
              loading="lazy"
            />
          </div>
        </div>

        <div className="col-lg-6">
          <div className="community-detail">
            <div className="sector-center">
              <div className="sectors-logo">
                <img src={iconURL} alt={iconAlt} loading="lazy" />
              </div>
              <h2 className="community-h1">
                {departmentName}
                <span className="sector-dot">.</span>
              </h2>
            </div>
            <Paragraphs
              nameOfClass="community-paragraph"
              paragraphText={departmentParagraph}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default SectorsBox;
