import React, { useEffect, useState } from "react";
import Contact from "../Common/Contact/Contact";
import Footer from "../Common/Footer/Footer";
import CompanyImage from "./CompanyImage";
import CompanyLocation from "./CompanyLocation";
import Hero from "./Hero";
import Timeline from "./Timeline";
import Loader from "../Common/Loader";

function CompanyProfile() {
  const [loader, setLoader] = useState(false);
  useEffect(() => {
    document.title = "Company Profile | Himont";
    setTimeout(() => {
      setLoader(true);
    }, 1500);
  }, [loader]);

  if (!loader) {
    return <Loader />;
  }
  return (
    <>
      <Hero />
      <Timeline />
      <CompanyLocation />
      <CompanyImage />
      <Contact />
      <Footer />
    </>
  );
}

export default CompanyProfile;
