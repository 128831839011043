import React from "react";
import Paragraphs from "../Common/Headings/Paragraphs";
import Fade from "react-reveal";
import images from "../../Constants/images";

function Hero() {
  return (
    <section className="department-bg">
      <div className="container">
        {/* <div className="row">
          <Fade bottom>
            <div className="col-lg-12">
              <div className="department-heading-center">
                <div className="departments-heading-box">
                  <h1 className="dep-hero-h1">
                    Department <span className="dep-hero-dot">.</span>
                  </h1>
                </div>
              </div>
              <div className="dep-hero-paragraph-box">
                <Paragraphs
                  nameOfClass="dep-hero-paragraph"
                  paragraphText="Connecting like-minded people, to achieve goals together for a
                better future."
                />
              </div>
            </div>
          </Fade>
        </div> */}
      </div>
    </section>
  );
}

export default Hero;
