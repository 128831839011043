import React from "react";
import Box from "./Box";
import MissionHeading from "../Common/Headings/MissionHeading";
import { Fade } from "react-reveal";

function Mission({ about }) {
  return (
    <section className="mission-background">
      <div className="container-fluid">
        <Fade bottom>
          <div className="row">
            <div className="col-lg-1"></div>
            <div className="col-lg-5">
              <MissionHeading
                page="about"
                subText="What we want to do"
                mainHeading="Next 10 Year  Mission."
                detail4="To be integral part of the healthcare management system by providing quality innovative products."
                detail1="To be a household name of reliability and quality in Pakistan."
                detail2="To be the standard to which other companies are compared."
                detail3="To continuously invest in Human Resource Development and acquire new technology in order to produce International Standards healthcare products ."
              />
            </div>
            <div className="col-lg-6 rotate">
              <div className="container">
                <div className="row">
                  <Box about={about} />
                </div>
              </div>
            </div>
          </div>
        </Fade>
      </div>
    </section>
  );
}

export default Mission;
