import React from "react";
import images from "../..//Constants/images";
import DrawerToggleButton from "../SideDrawer/DrawerToggleButton";
import {
  companyProfile,
  csr,
  mediaAndEvents,
  products,
  about,
  careers,
  research,
  applynow,
  contact,
  manufacturing_facility,
  globalSupplyChain,
  departments,
  supplychain,
  Pharmacovigilance,
  qualitymanagement,
} from "../../Constants/urls";
import { NavLink, Link } from "react-router-dom";

function Toolbar({ drawerClickHandler }) {
  const [selectedOption, setSelectedOption] = React.useState("");

  function handleChange(event) {
    setSelectedOption(event.target.value);
  }

  return (
    <header className="toolbar">
      <nav className="toolbar__navigation">
        <div className="toolbar-logo">
          <a href="/">
            <img src={images.HG} alt="unable to load" />
          </a>
        </div>
        <div className="toolbar__toggle-button">
          <DrawerToggleButton click={drawerClickHandler} />
        </div>

        <div className="toolbar_navigation-items">
          <ul>
            <li>
              <NavLink
                to="/"
                className={({ isActive }) =>
                  isActive
                    ? "active-toolbar toolbar-heading"
                    : "toolbar-heading"
                }
              >
                Home
              </NavLink>
            </li>
            <li>
              <NavLink
                className={({ isActive }) =>
                  isActive
                    ? "active-toolbar toolbar-heading"
                    : "toolbar-heading"
                }
                to={`/${about}`}
              >
                About Us
              </NavLink>
            </li>
            <li>
              <NavLink
                className={({ isActive }) =>
                  isActive
                    ? "active-toolbar toolbar-heading"
                    : "toolbar-heading"
                }
                to={`/${companyProfile}`}
              >
                Company Profile
              </NavLink>
            </li>
            <li>
              <NavLink
                className={({ isActive }) =>
                  isActive
                    ? "active-toolbar toolbar-heading"
                    : "toolbar-heading"
                }
                to={`/${departments}`}
              >
                Departments
              </NavLink>
            </li>
            <li>
              <a
                href="https://himont.com/shop/"
                activeClassName="active-toolbar"
                className="toolbar-heading"
              >
                Products
              </a>
            </li>
            <li>
              <NavLink
                className={({ isActive }) =>
                  isActive
                    ? "active-toolbar toolbar-heading"
                    : "toolbar-heading"
                }
                to={`/${mediaAndEvents}`}
              >
                Media & Events
              </NavLink>
            </li>
            <li>
              <div className="dropdown">
                <NavLink
                  className={({ isActive }) =>
                    isActive
                      ? "active-toolbar toolbar-heading"
                      : "toolbar-heading"
                  }
                  to={`/${csr}`}
                >
                  CSR
                </NavLink>

                <div className="dropdown-content">
                  <NavLink
                    className={({ isActive }) =>
                      isActive
                        ? "active-toolbar toolbar-heading"
                        : "toolbar-heading"
                    }
                    to={`/${csr}#Pharmacovigilance`}
                  >
                    Pharmacovigilance
                  </NavLink>
                  {/* <a
                    className="toolbar-heading"
                    href={`/${csr}#Pharmacovigilance`}
                  >
                    Pharmacovigilance
                  </a> */}
                </div>
              </div>
            </li>

            <li>
              <div className="dropdown">
                <div className="toolbar-heading">Supply Chain</div>

                <div className="dropdown-content">
                  <a href={`/${globalSupplyChain}`}> Global Supply chain</a>
                  <a href={`/${manufacturing_facility}`}>
                    Manufacturing Facility
                  </a>
                  <a href={`/${qualitymanagement}`}> Quality Management</a>
                  <a href={`/${research}`}> R&D and Innovation</a>
                </div>
              </div>
            </li>
            <li>
              <NavLink
                className={({ isActive }) =>
                  isActive
                    ? "active-toolbar toolbar-heading"
                    : "toolbar-heading"
                }
                to={`/${careers}`}
              >
                Careers
              </NavLink>
            </li>
            <li>
              <NavLink
                className={({ isActive }) =>
                  isActive
                    ? "active-toolbar toolbar-heading"
                    : "toolbar-heading"
                }
                to={`/${contact}`}
              >
                Contact
              </NavLink>
            </li>
          </ul>
        </div>
      </nav>
    </header>
  );
}

export default Toolbar;
