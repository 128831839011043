import React, { useEffect } from "react";
import Testimonial from "../Common/Testimonial/Testimonial";
import ProductSection from "../Main/Product/ProductSection";
import ProductsHero from "./ProductsHero";
// import images from "../../Constants/images";
import Contact from "../Common/Contact/Contact";
import Footer from "../Common/Footer/Footer";
import { useAllPrismicDocumentsByType } from "@prismicio/react";
import Loader from "../Common/Loader";

function Products() {
  const [product] = useAllPrismicDocumentsByType("product");
  useEffect(() => {
    document.title = "Products | Himont";
  }, []);
  return (
    <>
      {product === undefined ? (
        <Loader />
      ) : (
        <>
          <ProductsHero product={product} />
          <ProductSection product={product} paddingBottom="64px" />
          {/* <Testimonial images={images} /> */}
          <Contact />
          <Footer />
        </>
      )}
    </>
  );
}

export default Products;
