import React from "react";
import Paragraphs from "../Common/Headings/Paragraphs";

function ResearchItem({
  reverse,
  imageURL,
  imageAlt,
  researchName,
  researchParagraph,
  points,
  pointsData,
}) {
  return (
    <div className={`research-items ${reverse ? "research-reverse" : ""}`}>
      <div className="col-lg-6 image-container">
        <div style={{ position: "relative" }}>
          <img width="100%" src={imageURL} alt={imageAlt} loading="lazy" />
        </div>
      </div>

      <div className="col-lg-6">
        <div className="research-items-box">
          <h2 className="research-name">{researchName}</h2>
          {points === false ? (
            <Paragraphs
              nameOfClass="research-paragraph"
              paragraphText={researchParagraph}
            />
          ) : (
            <ul>
              {pointsData.map((i, index) => (
                <li key={index}>
                  <Paragraphs
                    nameOfClass="research-paragraph"
                    paragraphText={i}
                  />
                </li>
              ))}
            </ul>
          )}
        </div>
      </div>
    </div>
  );
}

export default ResearchItem;
