import React from "react";
import images from "../../Constants/images";
import Fade from "react-reveal";
import Paragraphs from "../Common/Headings/Paragraphs";

function Hero({}) {
  return (
    <section className="csr">
      <div className="container">
        <Fade bottom>
          <div className="row">
            <div className="col-lg-6 c-main-center">
              <div style={{ position: "relative" }}>
                <h1 className="main-heading">Start a Career with Himont</h1>
                <svg
                  height="19"
                  viewBox="0 0 178 19"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    opacity="0.5"
                    d="M5 14C50.3286 8.35706 147.189 -0.439291 172 9.51884"
                    stroke="#CB343B"
                    strokeWidth="10"
                    strokeLinecap="round"
                  />
                </svg>
              </div>

              <Paragraphs
                nameOfClass="c-main-paragraph"
                paragraphText="At Himont, we're dedicated to making a difference in people's
                lives by developing innovative healthcare solutions."
              />
            </div>

            <div className="col-lg-6">
              <img
                src={images?.contactImage}
                alt="Career"
                loading="lazy"
                width="100%"
              />
            </div>
          </div>
        </Fade>
      </div>
    </section>
  );
}

export default Hero;
