import React, { useEffect, useState } from "react";
import Footer from "../Common/Footer/Footer";
import Banner from "./Banner";
import ManufacturingDetail from "./ManufacturingDetail";
import { useAllPrismicDocumentsByType } from "@prismicio/react";
import Loader from "../Common/Loader";

function Manufacturing() {
  const [manufacture] = useAllPrismicDocumentsByType("manufacturing");
  const [loader, setLoader] = useState(false);

  useEffect(() => {
    document.title = "Manufacturing Facility | Himont";
    setTimeout(() => {
      setLoader(true);
    }, 1000);
  }, [loader]);

  if (!loader) {
    return <Loader />;
  }
  return (
    <>
      {manufacture === undefined ? (
        <Loader />
      ) : (
        <>
          <Banner manufacture={manufacture} />
          <ManufacturingDetail manufacture={manufacture} />
          <Footer />
        </>
      )}
    </>
  );
}

export default Manufacturing;
