import React from "react";
import images from "../..//Constants/images";
import DrawerToggleButton from "../SideDrawer/DrawerToggleButton";
import {
  companyProfile,
  csr,
  mediaAndEvents,
  products,
  about,
  careers,
  research,
  applynow,
  contact,
  manufacturing_facility,
  globalSupplyChain,
  departments,
  supplychain,
  Pharmacovigilance,
  qualitymanagement,
} from "../../Constants/urls";
import { NavLink } from "react-router-dom";

function SideDrawer({ showdisplay, SetsideDrawerOpen }) {
  let drawerClasses = ["side-drawer"];
  if (showdisplay) {
    drawerClasses = "side-drawer open";
  }
  return (
    <nav className={drawerClasses}>
      <ul>
        <li>
          <NavLink
            to="/"
            className={({ isActive }) =>
              isActive ? "active-toolbar toolbar-heading" : "toolbar-heading"
            }
            onClick={() => SetsideDrawerOpen(!showdisplay)}
          >
            Home
          </NavLink>
        </li>
        <li>
          <NavLink
            className={({ isActive }) =>
              isActive ? "active-toolbar toolbar-heading" : "toolbar-heading"
            }
            to={`/${about}`}
            onClick={() => SetsideDrawerOpen(!showdisplay)}
          >
            About Us
          </NavLink>
        </li>
        <li>
          <NavLink
            className={({ isActive }) =>
              isActive ? "active-toolbar toolbar-heading" : "toolbar-heading"
            }
            to={`/${companyProfile}`}
            onClick={() => SetsideDrawerOpen(!showdisplay)}
          >
            Company Profile
          </NavLink>
        </li>
        <li>
          <NavLink
            className={({ isActive }) =>
              isActive ? "active-toolbar toolbar-heading" : "toolbar-heading"
            }
            to={`/${departments}`}
            onClick={() => SetsideDrawerOpen(!showdisplay)}
          >
            Departments
          </NavLink>
        </li>
        <li>
          <NavLink
            className={({ isActive }) =>
              isActive ? "active-toolbar toolbar-heading" : "toolbar-heading"
            }
            to={`/${products}`}
            onClick={() => SetsideDrawerOpen(!showdisplay)}
          >
            Products
          </NavLink>
        </li>
        <li>
          <NavLink
            className={({ isActive }) =>
              isActive ? "active-toolbar toolbar-heading" : "toolbar-heading"
            }
            to={`/${mediaAndEvents}`}
            onClick={() => SetsideDrawerOpen(!showdisplay)}
          >
            Media & Events
          </NavLink>
        </li>
        <li>
          <div className="dropdown">
            <NavLink
              className={({ isActive }) =>
                isActive ? "active-toolbar toolbar-heading" : "toolbar-heading"
              }
              to={`/${csr}`}
              onClick={() => SetsideDrawerOpen(!showdisplay)}
            >
              CSR
            </NavLink>

            <div className="dropdown-content">
              <a href={`/${Pharmacovigilance}`}>Pharmacovigilance</a>
            </div>
          </div>
        </li>

        <li>
          <NavLink
            className={({ isActive }) =>
              isActive ? "active-toolbar toolbar-heading" : "toolbar-heading"
            }
            to={`/${globalSupplyChain}`}
            onClick={() => SetsideDrawerOpen(!showdisplay)}
          >
            Global Supply Chain
          </NavLink>
        </li>
        <li>
          <NavLink
            className={({ isActive }) =>
              isActive ? "active-toolbar toolbar-heading" : "toolbar-heading"
            }
            to={`/${manufacturing_facility}`}
            onClick={() => SetsideDrawerOpen(!showdisplay)}
          >
            Manufacturing Facility
          </NavLink>
        </li>
        <li>
          <NavLink
            className={({ isActive }) =>
              isActive ? "active-toolbar toolbar-heading" : "toolbar-heading"
            }
            to={`/${qualitymanagement}`}
            onClick={() => SetsideDrawerOpen(!showdisplay)}
          >
            Quality Management
          </NavLink>
        </li>
        <li>
          <NavLink
            className={({ isActive }) =>
              isActive ? "active-toolbar toolbar-heading" : "toolbar-heading"
            }
            to={`/${research}`}
            onClick={() => SetsideDrawerOpen(!showdisplay)}
          >
            R&D and Innovation
          </NavLink>
        </li>
        <li>
          <NavLink
            className={({ isActive }) =>
              isActive ? "active-toolbar toolbar-heading" : "toolbar-heading"
            }
            to={`/${careers}`}
            onClick={() => SetsideDrawerOpen(!showdisplay)}
          >
            Careers
          </NavLink>
        </li>
        <li>
          <NavLink
            className={({ isActive }) =>
              isActive ? "active-toolbar toolbar-heading" : "toolbar-heading"
            }
            to={`/${contact}`}
            onClick={() => SetsideDrawerOpen(!showdisplay)}
          >
            Contact
          </NavLink>
        </li>
      </ul>
    </nav>
  );
}

export default SideDrawer;
