import React from "react";
import { careers, contact, csr } from "../../../Constants/urls";

function FooterLinkThree() {
  return (
    <div className="col-lg-2">
      <div className="align-start">
        <a href={`/${csr}`}>
          <p className="footer-pinpoint">CSR</p>
        </a>
        <a href={`/${careers}`}>
          <p className="footer-pinpoint">Careers</p>
        </a>
        <a href={`/${contact}`}>
          <p className="footer-pinpoint">Contact</p>
        </a>
      </div>
    </div>
  );
}

export default FooterLinkThree;
