import React, { useEffect, useState } from "react";
import Footer from "../Common/Footer/Footer";
import Banner from "./Banner";
import Operations from "./Operations";
import OperationsDetail from "./OperationsDetail";
import { useAllPrismicDocumentsByType } from "@prismicio/react";
import Loader from "../Common/Loader";

function GSC() {
  const [global] = useAllPrismicDocumentsByType("global-supply-chain");
  const [loader, setLoader] = useState(false);
  useEffect(() => {
    document.title = "Global Supply Chain | Himont";
    setTimeout(() => {
      setLoader(true);
    }, 1500);
  }, [loader]);

  if (!loader) {
    return <Loader />;
  }

  return (
    <>
      {global === undefined ? (
        <Loader />
      ) : (
        <>
          <Banner global={global} />
          <Operations global={global} />
          <OperationsDetail />
          <Footer />
        </>
      )}
    </>
  );
}

export default GSC;
