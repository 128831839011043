import React, { useEffect } from "react";
import Hero from "./Hero";
import Videoplayer from "./Videoplayer";
import Follow from "./Follow";
import Event from "./Event";
import Footer from "../Common/Footer/Footer";
import { useAllPrismicDocumentsByType } from "@prismicio/react";
import Loader from "../Common/Loader";

function Media() {
  const [media] = useAllPrismicDocumentsByType("media");
  useEffect(() => {
    document.title = "Media And Events | Himont";
  }, []);
  return (
    <>
      {media === undefined ? (
        <Loader />
      ) : (
        <>
          <Hero media={media} />
          <Videoplayer />
          <Follow />
          <Event />
          <Footer />
        </>
      )}
    </>
  );
}

export default Media;
