// import Vector7 from "../assets/Vector7.png";
// import med from "../assets/Med.png";
// import banner from "../assets/banner-bg.png";
// import HeroBannerImg from "../assets/hero-banner-img.png";
// import CEO from "../assets/Group 572.png";
// import bg from "../assets/ceo-bg.png";
// import innovation from "../assets/Innovation.png";
// import Product1 from "../assets/Product1.png";
// import Product2 from "../assets/Product2.png";
// import Right from "../assets/right-arrow.png";
// import Scholarship from "../assets/Scholarship.png";
// import Internship from "../assets/Internship.png";
// import Community from "../assets/Community.png";
// import uparrow from "../assets/up-arrow.png";
// import Examination from "../assets/Examination.png";
// import Earth from "../assets/Earthquakes.png";
// import inam from "../assets/inam-ul-haq.png";
// import masood from "../assets/masood.png";
// import saami from "../assets/Saami-siddiqui.png";
// import Csrban from "../assets/csr-banner-img.png";
// import diagonstic from "../assets/diagonstic.png";
// import valley from "../assets/valley.png";
// import Hospital from "../assets/Hospital.png";
// import Simple from "../assets/Simple.png";
// import Reasearch from "../assets/Reasearch.png";
// import Academics from "../assets/Academics.png";
// import curved from "../assets/curved1.png";
// import company from "../assets/company-logo.png";
// import instagram from "../assets/instagram.png";
// import facebook from "../assets/facebook.png";
// import youtube from "../assets/youtube.png";
// import linkedin from "../assets/linkedin.png";
// import pinterest from "../assets/pinterest.svg";
// import tiktok from "../assets/tiktok.svg";
// import twitter from "../assets/twitter.svg";
// import place1 from "../assets/place1.png";
// import place2 from "../assets/place2.png";
// import place3 from "../assets/place3.png";
// import place4 from "../assets/place4.png";
// import place5 from "../assets/place5.png";
// import place6 from "../assets/place6.png";
// import place7 from "../assets/place7.png";
// import Videoplayer from "../assets/videoplayer.png";
// import instagram1 from "../assets/Instagram1.png";
// import facebook1 from "../assets/facebook1.png";
// import linkedin1 from "../assets/linkedin1.png";
// import youtube1 from "../assets/youtube1.png";
// import Event1 from "../assets/Event1.png";
// import Event2 from "../assets/Event2.png";
// import Event3 from "../assets/Event3.png";
// import Event4 from "../assets/Event4.png";
// import Event5 from "../assets/Event5.png";
// import Event6 from "../assets/Event6.png";
// import ourProductBanner from "../assets/our-product-banner.png";
// import testimonialImg from "../assets/testimonial-img.png";
// import columns from "../assets/columns.png";
import contactImage from "../assets/contact-img.png";
// import emailIcon from "../assets/contact-icon.png";
// import phoneIcon from "../assets/phone-icon.png";
// import companyImgLarge from "../assets/cp-image-lg.png";
// import companyImgSmall from "../assets/cp-image-sm.png";
import logo from "../assets/logo.png";
// import team from "../assets/team-member.png";
// import group1 from "../assets/group1.png";
// import group2 from "../assets/group2.png";
// import group3 from "../assets/group3.png";
// import group4 from "../assets/group4.png";
// import INTERNSHIPS from "../assets/CSR-INTERNSHIPS.png";
// import doublearrow from "../assets/doublearrow.svg";
// import university from "../assets/university.png";
// import rfacebook from "../assets/rfacebook.png";
// import rlinkedin from "../assets/rlinkedin.png";
// import ryoutube from "../assets/ryoutube.png";
// import rinstagram from "../assets/rinstagram.png";
import HG from "../assets/HG-Logo.png";
// import manu_img from "../assets/manu-img.png";
// import manu_main from "../assets/manu-main.png";
// import gsc_banner from "../assets/gsc-banner.png";
// import pakistan from "../assets/pakistan.png";
// import country_1 from "../assets/country-1.png";
// import country_2 from "../assets/country-2.png";
// import Good from "../assets/Good Quality.png";
// import web from "../assets/Web Accessibility.png";
// import surgery from "../assets/Surgery.png";
// import sales from "../assets/sales.png";
// import marketing from "../assets/marketing.png";
// import finance from "../assets/finance.png";
// import Hr from "../assets/Hr.png";
// import supplychain from "../assets/supplychain.png";
// import production from "../assets/production.png";
// import procurement from "../assets/production.png";

// import TotalSales from "../assets/Total_Sales.png";
// import marketlogo from "../assets/Marketing-logo.png";
// import GlobalFinance from "../assets/Global_Finance.png";
// import HumanResources from "../assets/Human_Resources.png";
// import supplylogo from "../assets/Supply_Chain.png";
// import Productionlogo from "../assets/Production_Line.png";
// import Procurementlogo from "../assets/Procurement-logo.png";
// import researchInnvoation from "../assets/research-innvoation.png";
// import csrDonation from "../assets/csr-donation.png";
// import marketingDepartments from "../assets/marketing-departments.png";
// import marketingIcon from "../assets/marketing-icon.png";
// import itDepartments from "../assets/it-departments.png";
// import productionDepartments from "../assets/production-department.png";
// import qualityManagementBanner from "../assets/quality-management-banner.png";
// import ourCommitment from "../assets/our-commitment.jpg";
// import cvrm from "../assets/cvrm.svg";
// import respiratory from "../assets/respiratory.svg";
// import nutrition from "../assets/nutrition.svg";
// import dermatology from "../assets/dermatology.svg";
// import gastroenterology from "../assets/gastroenterology.svg";
// import obstetrics from "../assets/obstetrics.svg";
// import gynaecology from "../assets/gynaecology.svg";
// import paediatrics from "../assets/paediatrics.png";
// import careersTeam from "../assets/careers-team.jpg";
// import herbalProduct from "../assets/Herbal.png";
// import guidelinesDoc from "../assets/Pakistan-National-Pharmacovigilance-guidlines-Approved.pdf";
// import r1 from "../assets/R1.png";
// import r2 from "../assets/R2.png";
// import r3 from "../assets/R3.png";
// import r4 from "../assets/R4.png";

// import d1 from "../assets/d1.png";
// import d2 from "../assets/d2.png";
// import d3 from "../assets/d3.png";
// import d4 from "../assets/d4.png";
// import d5 from "../assets/d5.png";
// import it from "../assets/it.png";
// import qbanner from "../assets/qualityManagement_banner.png";
// import q1 from "../assets/q1.png";
// import q2 from "../assets/q2.png";
// import q3 from "../assets/q3.png";
// import afganistan from "../assets/afganistan.png";
// import ivory from "../assets/ivory.png";
// import phillipines from "../assets/phillipines.png";
// import yemen from "../assets/yemens.png";
// import phillipinesmap from "../assets/phillipinesmap.png";
// import carrier1 from "../assets/carrier1.png";
// import carrier2 from "../assets/carrier2.png";
// import carrier3 from "../assets/carrier3.png";
// import carrier4 from "../assets/carrier4.png";
// import carrier5 from "../assets/carrier5.png";
// import carrier6 from "../assets/carrier6.png";
// import carrier7 from "../assets/carrier7.png";
// import carrier8 from "../assets/carrier8.png";
// import kenya from "../assets/kenya.png";
// import video from "../assets/video.gif";
// import qualityManagement_inclusionAndDiversity from "../assets/qualityManagement_inclusionAndDiversity.png";
// import rndGif from "../assets/rnd.gif";
// import home_banner_1 from "../assets/home-banner-1.png";
// import productNew from "../assets/productNew.png";
export default {
  HG,
  contactImage,
  logo,
};
// export default {
//   uparrow,
//   productNew,
//   home_banner_1,
//   rndGif,
//   qualityManagement_inclusionAndDiversity,
//   video,
//   carrier1,
//   carrier2,
//   carrier3,
//   carrier4,
//   carrier5,
//   carrier6,
//   carrier7,
//   carrier8,
//   phillipinesmap,
//   kenya,
//   afganistan,
//   yemen,
//   ivory,
//   phillipines,
//   q1,
//   q2,
//   q3,
//   qbanner,
//   d1,
//   d2,
//   d3,
//   d4,
//   d5,
//   it,
//   r1,
//   r2,
//   r3,
//   r4,
//   med,
//   guidelinesDoc,
//   herbalProduct,
//   careersTeam,
//   cvrm,
//   respiratory,
//   nutrition,
//   dermatology,
//   gastroenterology,
//   obstetrics,
//   gynaecology,
//   paediatrics,
//   TotalSales,
//   marketlogo,
//   GlobalFinance,
//   HumanResources,
//   supplylogo,
//   Productionlogo,
//   Procurementlogo,
//   sales,
//   marketing,
//   finance,
//   Hr,
//   supplychain,
//   production,
//   procurement,
//   Good,
//   web,
//   surgery,
//   HG,
//   rfacebook,
//   rinstagram,
//   ryoutube,
//   rlinkedin,
//   university,
//   doublearrow,
//   INTERNSHIPS,
//   group1,
//   group2,
//   group3,
//   group4,
//   team,
//   Event1,
//   Event2,
//   Event3,
//   Event4,
//   Event5,
//   Event6,
//   instagram1,
//   facebook1,
//   linkedin1,
//   youtube1,
//   Videoplayer,
//   place1,
//   place2,
//   place3,
//   place4,
//   place5,
//   place6,
//   place7,
//   company,
//   instagram,
//   facebook,
//   youtube,
//   linkedin,
//   curved,
//   diagonstic,
//   Csrban,
//   Right,
//   Product1,
//   Product2,
//   innovation,
//   CEO,
//   Vector7,
//   banner,
//   bg,
//   HeroBannerImg,
//   Scholarship,
//   Internship,
//   Community,
//   Examination,
//   Earth,
//   inam,
//   masood,
//   saami,
//   valley,
//   Hospital,
//   Simple,
//   Reasearch,
//   Academics,
//   ourProductBanner,
//   testimonialImg,
//   columns,
// contactImage,
//   emailIcon,
//   phoneIcon,
//   companyImgLarge,
//   companyImgSmall,
//   logo,
//   manu_img,
//   manu_main,
//   gsc_banner,
//   pakistan,
//   country_1,
//   country_2,
//   researchInnvoation,
//   csrDonation,
//   marketingDepartments,
//   marketingIcon,
//   itDepartments,
//   productionDepartments,
//   pinterest,
//   tiktok,
//   twitter,
//   qualityManagementBanner,
//   ourCommitment,
// };
