import React, { useEffect, useState } from "react";
import Footer from "../Common/Footer/Footer";
import QM_Banner from "./QM_Banner";
import QualitySection from "./QualitySection";
import { useAllPrismicDocumentsByType } from "@prismicio/react";
import Loader from "../Common/Loader";

function QualityManagement() {
  const [quality] = useAllPrismicDocumentsByType("quality-management");
  const [loader, setLoader] = useState(false);
  useEffect(() => {
    document.title = "Quality Management | Himont";
    setTimeout(() => {
      setLoader(true);
    }, 1500);
  }, [loader]);

  if (!loader) {
    return <Loader />;
  }

  return (
    <>
      {quality === undefined ? (
        <Loader />
      ) : (
        <>
          <QM_Banner quality={quality} />
          <QualitySection quality={quality} />
          <Footer />
        </>
      )}
    </>
  );
}

export default QualityManagement;
