import React from "react";
import images from "../../Constants/images";
import Paragraphs from "../Common/Headings/Paragraphs";
import Fade from "react-reveal";

function Hero({ media }) {
  return (
    <>
      <section className="event-bg">
        <div className="container">
          <div className="row">
            <div className="col-lg-2"></div>
            <div className="col-lg-8">
              <Fade bottom>
                <div className="text-center">
                  <div style={{ position: "relative" }}>
                    <h1 className="main-heading">Media & Events.</h1>
                    <svg
                      className="our-products-svg"
                      width="358"
                      height="19"
                      viewBox="0 0 358 19"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        opacity="0.5"
                        d="M5 14C99.4571 8.35706 301.297 -0.439291 353 9.51884"
                        stroke="#CB343B"
                        strokeWidth="10"
                        strokeLinecap="round"
                      />
                    </svg>
                  </div>
                  <Paragraphs
                    nameOfClass="event-paragraph"
                    paragraphText="At Himont, we're dedicated to making a difference in people's
                  lives by developing innovative healthcare solutions."
                  />
                </div>
              </Fade>
            </div>
            <div className="col-lg-2"></div>
          </div>
        </div>
        <div className="place-padding">
          <div className="container-fluid">
            <Fade bottom>
              <div className="row">
                <div className="col-lg-3">
                  <img
                    className="place-img"
                    src={media[0]?.data?.hero_img1?.url}
                    alt="hero-1"
                    loading="lazy"
                  />
                </div>
                <div className="col-lg-3">
                  <img
                    className="place-img"
                    src={media[0]?.data?.hero_img2?.url}
                    alt="hero-2"
                    loading="lazy"
                  />
                </div>
                <div className="col-lg-3">
                  <img
                    className="place-img"
                    src={media[0]?.data?.hero_img3?.url}
                    alt="hero-3"
                    loading="lazy"
                  />
                </div>
                <div className="col-lg-3">
                  <img
                    className="place-img"
                    src={media[0]?.data?.hero_img4?.url}
                    alt="hero-4"
                    loading="lazy"
                  />
                </div>
              </div>
            </Fade>
            <Fade bottom>
              <div className="row">
                <div className="col-lg-12 place-adjust">
                  <div className="col-lg-4">
                    <img
                      className="place-img2"
                      src={media[0]?.data?.hero_img5?.url}
                      alt="hero-5"
                      loading="lazy"
                    />
                  </div>
                  <div className="col-lg-4">
                    <img
                      className="place-img2"
                      src={media[0]?.data?.hero_img6?.url}
                      alt="hero-6"
                      loading="lazy"
                    />
                  </div>
                  <div className="col-lg-4">
                    <img
                      className="place-img2"
                      src={media[0]?.data?.hero_img7?.url}
                      alt="hero-7"
                      loading="lazy"
                    />
                  </div>
                </div>
              </div>
            </Fade>
          </div>
        </div>
      </section>
    </>
  );
}

export default Hero;
