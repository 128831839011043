import React from "react";

function ProductOverlay({
  seeMore,
  setSeeMore,
  heading,
  paragraphOne,
  paragraphTwo,
}) {
  return (
    <div
      className="product-overlay"
      onClick={() => setSeeMore(false)}
      style={{ display: seeMore ? "flex" : "none" }}
    >
      <div className="container">
        <div className="row">
          <div className="col-lg-6 offset-lg-3">
            <div className="product-info-box-overlay product-info-box pink-border">
              <h2 className="product-heading">{heading}</h2>
              <p className="base-paragraph product-paragraph">{paragraphOne}</p>
              <p className="base-paragraph product-paragraph">{paragraphTwo}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProductOverlay;
