export const youtube = "https://www.youtube.com/@himontgroup-1994/featured";
export const linkedin = "https://www.linkedin.com/company/himont-group/";
export const facebook =
  "https://www.facebook.com/profile.php?id=100090669393111";
export const instagram = "https://www.instagram.com/himontofficial/";
export const twitter = "https://twitter.com/himontgroup1994";
export const tiktok = "https://www.tiktok.com/@himontgroup";
export const pinterest = "https://www.pinterest.com/himontgroup/_created/";
export const telephone = "04235813828";
export const contactMail = "sales@himont.com";
export const recruitment = "recruitment@himont.com";
//
//
//
//
//
//Routes
export const products = "our-products";
export const pharma = "pharmaceutical";
export const nutraceuticals = "nutraceuticals";
export const herbal = "herbal";
export const skinCare = "skin-care";
export const consumer = "consumer";
export const about = "about";
export const companyProfile = "company-profile";
export const globalSupplyChain = "global-supply-chain";
export const departments = "departments";
export const mediaAndEvents = "media-and-events";
export const csr = "csr";
export const careers = "careers";
export const contact = "contact";
export const research = "research-and-development";
export const applynow = "apply-now";
export const manufacturing_facility = "manufacturing-facility";
export const supplychain = "supply-chain";
export const Pharmacovigilance = "Pharmacovigilance";
export const qualitymanagement = "quality-management";
