import React from "react";
import images from "../../../Constants/images";

function Product3({
  url,
  alt,
  setSeeMore,
  setParagraphOne,
  setParagraphTwo,
  setHeading,
}) {
  const openLink = () => {
    window.open(
      "https://himont.com/shop/product-category/skincare-products/",
      "_blank"
    );
  };
  return (
    <>
      <div className="col-lg-7">
        <div className="product-section pink-bg">
          <div className="circle-lg light-pink-bg circle-lg-right"></div>
          <div className="circle-box light-pink-bg">
            <img className="product-img" src={url} alt={alt} loading="lazy" />
          </div>
          <div className="circle-sm light-pink-bg circle-sm-right"></div>
        </div>
      </div>

      <div className="col-lg-5 product-center">
        <div className="product-info-box pink-border right-product-info">
          <h2 className="product-heading">Derma Products</h2>
          <p className="base-paragraph product-paragraph">
            Developed by physicians, trusted by professionals, loved by you. We
            offer non-toxic skincare products that prioritize health and results
            without compromising on natural and high-performance ingredients.
          </p>
          <p className="base-paragraph product-paragraph hide-paragraph">
            Our Formulas are continuously adapted and modernized to meet (and
            exceed) regulatory guidelines to ensure our products are globally
            compliant and above all, safe. We use superior raw materials and
            test our products extensively to ensure their effectiveness. Our
            products are tried, true and tested by a team of professionals to
            help our customers feel the most confident in their skin. We take
            care of the science so, our products can take care of your skin.
          </p>
          <div
            onClick={() => {
              setSeeMore(true);
              setParagraphOne(`Developed by physicians, trusted by professionals, loved by you. We
              offer non-toxic skincare products that prioritize health and results
              without compromising on natural and high-performance ingredients.`);
              setParagraphTwo(`Our Formulas are continuously adapted and modernized to meet (and
                exceed) regulatory guidelines to ensure our products are globally
                compliant and above all, safe. We use superior raw materials and
                test our products extensively to ensure their effectiveness. Our
                products are tried, true and tested by a team of professionals to
                help our customers feel the most confident in their skin. We take
                care of the science so, our products can take care of your skin.`);
              setHeading(`Derma Products`);
            }}
            className="product-see-more"
          >
            Read More
          </div>
          <button className="view-product-btn" onClick={openLink}>
            View Derma Products
          </button>
        </div>
      </div>
    </>
  );
}

export default Product3;
