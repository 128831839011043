import "./App.css";
import React, { useState } from "react";

import Landing from "./Components/Main/Main";
import CRS from "./Components/CSR/CRS";
import { Routes, Route } from "react-router-dom";
import Media from "./Components/Media&Events/Media";
import Research from "./Components/Research/Research";
import {
  companyProfile,
  csr,
  mediaAndEvents,
  products,
  about,
  careers,
  research,
  applynow,
  contact,
  manufacturing_facility,
  globalSupplyChain,
  departments,
  qualitymanagement,
} from "./Constants/urls";
import Products from "./Components/Products/Products";
import CompanyProfile from "./Components/CompanyProfile/CompanyProfile";
import About from "./Components/About/About";
import Career from "./Components/Career/Career";
import Apply from "./Components/Apply/Apply";
import Toolbar from "./Components/Toolbar/Toolbar";
import SideDrawer from "./Components/SideDrawer/SideDrawer";
import Backdrop from "./Components/Backdrop/Backdrop";
import Manufacturing from "./Components/Manufacturing/Manufacturing";
import GSC from "./Components/GSC/GSC";
import ContactPage from ".././src/Components/ContactPage/ContactPage";
import Department from "./Components/Department/Department";
import QualityManagement from "./Components/QualityManagement/QualityManagement";

import Button from "./Components/Common/Button";
import ScrollToTop from "./Components/Common/ScrolltoTop";
import "../src/assets/bootstrap.css";
import Loader from "./Components/Common/Loader";

function App() {
  const [sideDrawerOpen, SetsideDrawerOpen] = useState(false);

  const drawerToggleClickHandler = () => {
    SetsideDrawerOpen(!sideDrawerOpen);
  };
  const backdropClickHandler = () => {
    SetsideDrawerOpen(false);
  };

  return (
    <React.Fragment>
      <Toolbar drawerClickHandler={drawerToggleClickHandler} />
      <SideDrawer
        showdisplay={sideDrawerOpen}
        SetsideDrawerOpen={SetsideDrawerOpen}
      />
      {sideDrawerOpen && <Backdrop backclick={backdropClickHandler} />}
      <ScrollToTop />
      <Button />

      <Routes>
        <Route path="/" element={<Landing />} />
        <Route path={`/${about}`} element={<About />} />
        <Route path={`/${companyProfile}`} element={<CompanyProfile />} />
        <Route path={`/${departments}`} element={<Department />} />
        <Route path={`/${products}`} element={<Products />} />
        <Route path={`/${mediaAndEvents}`} element={<Media />} />
        <Route path={`/${csr}`} element={<CRS />} />
        <Route path={`/${globalSupplyChain}`} element={<GSC />} />
        <Route
          path={`/${manufacturing_facility}`}
          element={<Manufacturing />}
        />
        <Route path={`/${qualitymanagement}`} element={<QualityManagement />} />
        <Route path={`/${research}`} element={<Research />} />
        <Route path={`/${careers}`} element={<Career />} />
        <Route path={`/${contact}`} element={<ContactPage />} />
        {/* <Route path={`/loader`} element={<Loader />} /> */}
        {/* <Route path={`/${applynow}`} element={<Apply />} /> */}
      </Routes>
    </React.Fragment>
  );
}

export default App;
