import React from "react";
import images from "../../Constants/images";
import Paragraphs from "../Common/Headings/Paragraphs";
import Fade from "react-reveal";
function Hero({ csr }) {
  return (
    <section className="csr">
      <div className="container">
        <Fade bottom>
          <div className="row">
            <div className="col-lg-6 c-main-center">
              <div style={{ position: "relative" }}>
                <h1 className="main-heading">
                  Corporate Social Responsibility.
                </h1>
                <svg
                  width="290"
                  height="20"
                  viewBox="0 0 290 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    opacity="0.5"
                    d="M5 14.0378C81 8.37117 243.4 -0.46216 285 9.53784"
                    stroke="#CB343B"
                    strokeWidth="10"
                    strokeLinecap="round"
                  />
                </svg>
              </div>
              <Paragraphs
                paragraphText="At Himont, we're dedicated to making a difference in people's
              lives by developing innovative healthcare solutions."
                nameOfClass="c-main-paragraph"
              />
            </div>

            <div className="col-lg-6">
              <img
                className="csr-img"
                src={csr[0]?.data?.csr_banner?.url}
                alt={csr[0]?.data?.csr_banner?.alt}
                loading="lazy"
              />
            </div>
          </div>
        </Fade>
      </div>
    </section>
  );
}

export default Hero;
