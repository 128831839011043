import React from "react";
import TopHeadingSection from "../Common/Headings/TopHeadingSection";
import Achievements from "./Achievements";
import { Fade } from "react-reveal";
function Benefits({ about }) {
  return (
    <section>
      <div className="container">
        <div className="row">
          <div className="col-12">
            <TopHeadingSection
              mainHeading="Achievements"
              description="“Because our team carries out our mission of providing healthy, happy lives, we are committed to being a loyal, supporting employer, so that they may continue to deliver the best.”"
            />
          </div>
        </div>

        <div className="row reverse-on-mobile achievement-margin">
          <Fade bottom>
            <Achievements
              heading="Production and Operations"
              paragraphText="Our production department stands as a beacon of sophistication within Pakistan, holding esteemed international Good Manufacturing Practice (GMP) certification. With an unwavering commitment to advancement, our operations have seamlessly integrated a fully automated filling and packaging system, eliminating the need for human intervention."
              departmentImage={about[0]?.data?.about_achievement_1?.url}
            />
          </Fade>
          <Fade bottom>
            <Achievements
              heading="Sales and Marketing"
              paragraphText="Our marketing and sales department stands unwaveringly at the vanguard, fueling our mission to make Himont better and bigger every day. With a passion for growth and an unwavering commitment to our customers, our Sales and Marketing team leaves no stone unturned in ensuring that Himont reaches new horizons of success. Through strategic market insights, cutting-edge campaigns, and unwavering dedication, our team strives to create a powerful brand presence that resonates with healthcare professionals and consumers alike."
              departmentImage={about[0]?.data?.about_achievement_2?.url}
            />
          </Fade>
          <Fade bottom>
            <Achievements
              heading="Finance"
              paragraphText="Our finance department has always been crucial in providing accurate financial information to important stakeholders and safeguarding the group's assets, including physical and intangible resources. They also ensure that Himont complies with local tax laws from a financial and fiduciary perspective. This department is an essential partner, offering expertise and input on economic, financial, and fiduciary matters. They work closely with all divisions within the company, maintaining transparency and implementing effective standards, processes, and controls."
              departmentImage={about[0]?.data?.about_achievement_3?.url}
            />
          </Fade>
          <Fade bottom>
            <Achievements
              heading="Supply Chain"
              paragraphText="Our supply chain department is the driving force behind our success. With innovation and a touch of customization, we have crafted a supply chain management system that's truly remarkable and completely aligns with our mission. Our automated online portal ensures that our products reach every nook and cranny of Pakistan, with impeccable timing, care, and safety. From bustling cities to remote corners, we go the extra mile to bring the right products to you. With our tech-savvy approach and attention to detail, we have transformed supply chain management into an art form."
              departmentImage={about[0]?.data?.about_achievement_4?.url}
            />
          </Fade>
        </div>
      </div>
    </section>
  );
}

export default Benefits;
