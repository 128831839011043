import React from "react";
import images from "../../Constants/images";

function Group3() {
  return (
    <div>
      <div className="row">
        <div className="center">
          <div className="col-lg-8">
            <img
              className="group-img"
              src={images.carrier5}
              alt="unable to load"
            />
          </div>
          <div className="col-lg-4">
            <img
              className="group-img"
              src={images.carrier6}
              alt="unable to load"
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Group3;
