import React, { useEffect } from "react";
import Hero from "./Hero";
import Role from "./Role/Role";
import Himontinsides from "../About/Himontinsies";
import Footer from ".././Common/Footer/Footer";
import { useAllPrismicDocumentsByType } from "@prismicio/react";
import Loader from "../Common/Loader";

function Career() {
  const [data] = useAllPrismicDocumentsByType("job");
  // const [career] = useAllPrismicDocumentsByType("career");

  useEffect(() => {
    document.title = "Careers | Himont";
  }, []);
  return (
    <>
      {data === undefined ? (
        <Loader />
      ) : (
        <>
          <Hero />
          <Role data={data} />
          {/* <Himontinsides /> */}
          <Footer />
        </>
      )}
    </>
  );
}

export default Career;
