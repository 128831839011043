import React, { useState } from "react";
import Loader from "../Loader";

function ContactForm({ setLoading }) {
  const [result, setResult] = useState("");

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");

  const onSubmit = async (event) => {
    event.preventDefault();

    if (name === "" || email === "" || message === "") {
      return;
    }

    setLoading(true);
    const formData = new FormData(event.target);
    formData.append("access_key", "1faff041-ecee-416c-944c-07ee18e9e249");

    const res = await fetch("https://api.web3forms.com/submit", {
      method: "POST",
      body: formData,
    }).then((res) => res.json());

    setLoading(false);

    if (res.success) {
      setResult(res.message);
      setEmail("");
      setName("");
      setMessage("");
      localStorage.setItem("email", JSON.stringify(email));
      localStorage.setItem("name", JSON.stringify(name));
    } else {
      setResult(res.message);
    }
  };

  return (
    <>
      <form className="form" onSubmit={onSubmit}>
        <div className="input-container">
          <label className="main-label-class" htmlFor="name">
            Name
          </label>
          <input
            className="main-input-class"
            type="text"
            name="name"
            id="name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            placeholder="Enter your name"
            autoComplete="true"
          />
        </div>
        <div className="input-container">
          <label className="main-label-class" htmlFor="email">
            Email
          </label>
          <input
            className="main-input-class"
            type="email"
            name="email"
            id="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Enter your email"
            autoComplete="true"
          />
        </div>
        <div className="input-container">
          <label className="main-label-class" htmlFor="message">
            Message
          </label>
          <textarea
            className="main-input-class text-area-class"
            type="text"
            name="message"
            id="message"
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            placeholder="Enter your message"
          />
        </div>
        <button className="contact-send-message">Send Message</button>
      </form>
    </>
  );
}

export default ContactForm;
