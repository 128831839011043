import React from "react";
import images from "../../Constants/images";
import Paragraphs from "../Common/Headings/Paragraphs";
import PolicyCircle from "./PolicyCircle";
import Fade from "react-reveal";

function TherapeuticAreas({ home }) {
  return (
    <section className="therapeutic-bg">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <Fade bottom>
              <h2 className="product-h2">
                Our Key Therapeutic Areas<span className="dot">.</span>
              </h2>
            </Fade>

            <Paragraphs nameOfClass="product-paragraph" />
          </div>
          <Fade bottom>
            <div className="col-lg-3">
              <PolicyCircle
                imageURL={home[0]?.data?.therapeutic_area_1?.url}
                altText={home[0]?.data?.therapeutic_area_1?.alt}
                heading="CVRM
              (Cardiometabolic, Renal & Metabolism)"
              />
            </div>
            <div className="col-lg-3">
              <PolicyCircle
                imageURL={home[0]?.data?.therapeutic_area_2?.url}
                altText={home[0]?.data?.therapeutic_area_2?.alt}
                heading="Respiratory"
              />
            </div>
            <div className="col-lg-3">
              <PolicyCircle
                imageURL={home[0]?.data?.therapeutic_area_3?.url}
                altText={home[0]?.data?.therapeutic_area_3?.alt}
                heading="Dermatology"
              />
            </div>
            <div className="col-lg-3">
              <PolicyCircle
                imageURL={home[0]?.data?.therapeutic_area_4?.url}
                altText={home[0]?.data?.therapeutic_area_4?.alt}
                heading="Gastroenterology"
              />
            </div>
          </Fade>
        </div>
        <Fade bottom>
          <div
            className="row"
            style={{ display: "flex", justifyContent: "space-evenly" }}
          >
            <div className="col-lg-3">
              <PolicyCircle
                imageURL={home[0]?.data?.therapeutic_area_5?.url}
                altText={home[0]?.data?.therapeutic_area_5?.alt}
                heading="Obstetrics"
              />
            </div>

            <div className="col-lg-3">
              <PolicyCircle
                imageURL={home[0]?.data?.therapeutic_area_6?.url}
                altText={home[0]?.data?.therapeutic_area_6?.alt}
                heading="Gynaecology"
              />
            </div>

            <div className="col-lg-3">
              <PolicyCircle
                imageURL={home[0]?.data?.therapeutic_area_7?.url}
                altText={home[0]?.data?.therapeutic_area_7?.alt}
                heading="Paediatrics"
              />
            </div>
          </div>
        </Fade>
      </div>
    </section>
  );
}

export default TherapeuticAreas;
