import React, { useEffect, useRef } from "react";
import Description from "./Description";
import Hero from "./Hero";
import Community from "./Community";
import Contact from "../Common/Contact/Contact";
import Footer from "../Common/Footer/Footer";
import Pharma from "./Pharma";
import { useAllPrismicDocumentsByType } from "@prismicio/react";
import { useHistory } from "react-router-dom";
import Loader from "../Common/Loader";

function CSR() {
  const [csr] = useAllPrismicDocumentsByType("csr");
  const pharmaRef = useRef(null);

  useEffect(() => {
    document.title = "Corporate Social Responsibility | Himont";
    if (window.location.hash === "#Pharmacovigilance" && pharmaRef.current) {
      setTimeout(() => {
        pharmaRef.current.scrollIntoView({
          behavior: "smooth",
          block: "start",
        });
      }, 0);
    }
  }, []);

  return (
    <>
      {csr === undefined ? (
        <Loader />
      ) : (
        <>
          <Hero csr={csr} />
          <Description />
          <Community csr={csr} />
          <div ref={pharmaRef} id="Pharmacovigilance">
            <Pharma csr={csr} />
          </div>
          <Contact />
          <Footer />
        </>
      )}
    </>
  );
}

export default CSR;
