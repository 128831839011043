import React, { useEffect, lazy, useState } from "react";
import Hero from "./Hero";
import HeadSection from "../Main/HeadSection";
import ProductSection from "../Main/Product/ProductSection";
import Team from "../Main/Team";
import Group from "../../Components/Main/Group/Group";
import Contact from ".././Common/Contact/Contact";
import Footer from "../Common/Footer/Footer";
import Himontinsides from "./Himontinsies";
import Mission from "./Mission";
import Benefits from "./Benefits";
import { useAllPrismicDocumentsByType } from "@prismicio/react";
import Loader from "../Common/Loader";
import CEOOverlay from "../Main/CEOOverlay";

const HeroImage = lazy(() => import("./Hero"));

function About() {
  const [about] = useAllPrismicDocumentsByType("about");
  const [product] = useAllPrismicDocumentsByType("product");

  useEffect(() => {
    document.title = "About | Himont";
  }, []);

  useEffect(() => {
    document.title = "Home | Himont";
  }, []);

  const setBodyScrollHidden = () => {
    document.body.style.overflow = "auto";
  };
  const [overlay, setOverlay] = useState(false);

  return (
    <>
      {about === undefined ? (
        <Loader />
      ) : (
        <>
          <CEOOverlay
            image={about[0]?.data?.ceo_image?.url}
            paragraph={[
              "With 30 years of excellence, Himont Group has established itself as a renowned Pharmaceutical and Herbal company. Leveraging our rich experience, we're now expanding into the FMCG sector, backed by our solid foundation in Pharmaceutical and Herbal businesses.",
              "Himont has been a pioneer in integrating herbal medications within Pakistan's Herbal market. Our trailblazing approach will now fuel our efforts to revolutionize the FMCG sector by challenging well-established brands.",
              "Adopting cutting-edge technology is vital to our FMCG strategy. Himont's emphasis lies in healthy drinks, vitamin-infused confectionery products, and a state-of-the-art, production facility managed by skilled professionals. Our goal is to manufacture top-quality, health-focused consumer goods by implementing best practices, enabling us to meet international benchmarks and export opportunities.",
              "Himont Group focuses on employee training and development which ensures continual improvement and organizational success across all divisions. Relentless training and development empowers our employees, ensuring the collective growth of the company and its people. This commitment is the essence of Himont Group's excellence and the foundation of our success.",
            ]}
            setOverlay={setOverlay}
            overlay={overlay}
            setBodyScrollHidden={setBodyScrollHidden}
          />
          <HeroImage about={about} />
          {/* <Hero about={about} /> */}
          <HeadSection home={about} setOverlay={setOverlay} overlay={overlay} />
          {product === undefined ? <></> : <ProductSection product={product} />}
          <Team />
          <Mission about={about} />
          <Himontinsides />
          <Benefits about={about} />
          <Contact />
          <Footer />
        </>
      )}
    </>
  );
}

export default About;
