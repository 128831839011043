import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { applynow, contactMail } from "../../../Constants/urls";
import { recruitment } from "../../../Constants/urls";

//
//
//

function JobRole({ setShowNewComponent, data, dataIndex, setDataIndex }) {
  //
  //

  //
  //

  const handleRemoveOverlay = () => {
    setShowNewComponent(false); // call the parent component's callback function
  };
  const handleApplyNow = () => {
    setShowNewComponent(false);
  };

  function getLIs(str) {
    return (
      "<li class='responsibility-list'>" +
      str.replace(/\n/g, "</li><li class='responsibility-list'>") +
      "</li>"
    );
  }

  function getLIsForEligibility(eligibilityString) {
    return (
      "<li class='responsibility-list'>" +
      eligibilityString.replace(
        /\n/g,
        "</li><li class='responsibility-list'>"
      ) +
      "</li>"
    );
  }

  function getLIsForSkills(skillsString) {
    return (
      "<li class='responsibility-list'>" +
      skillsString.replace(/\n/g, "</li><li class='responsibility-list'>") +
      "</li>"
    );
  }

  useEffect(() => {
    if (dataIndex !== false) {
      document.getElementById("responsibility-id").innerHTML = getLIs(
        data[dataIndex]?.data?.responsibilities[0]?.text
      );
      document.getElementById("eligibility-id").innerHTML =
        getLIsForEligibility(
          data[dataIndex]?.data?.eligibility_criteria[0]?.text
        );
      document.getElementById("skills-id").innerHTML = getLIsForSkills(
        data[dataIndex]?.data?.skills[0]?.text
      );
    }
  }, [dataIndex]);

  //
  //
  return (
    <>
      <section className="job-bg">
        {dataIndex === false ? (
          <></>
        ) : (
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div>
                  <button
                    className="close-btn"
                    onClick={() => {
                      handleRemoveOverlay();
                      setDataIndex(false);
                    }}
                  >
                    <span className="cross" aria-hidden="true">
                      &times;
                    </span>
                    Close
                  </button>
                </div>
              </div>
              <div className="col-lg-12 first-container">
                <p className="job-h1">
                  {data[dataIndex]?.data?.job_title[0]?.text}
                </p>
              </div>
              <div className="col-lg-12">
                <p className="overview-h1">Key Responsibilities:</p>
                <ul id="responsibility-id"></ul>
              </div>
              <div className="col-lg-12">
                <p className="responsibility-h1">Eligibility Criteria:</p>
                <ul id="eligibility-id"></ul>
              </div>

              <div className="col-lg-12">
                <p className="responsibility-h1">Skills:</p>
                <ul id="skills-id"></ul>
              </div>

              <div className="col-lg-12">
                <p className="responsibility-h1">Location:</p>
                <ul>
                  <li className="responsibility-list">
                    {data[dataIndex]?.data?.location[0]?.text}
                  </li>
                </ul>
              </div>
              <div className="col-lg-12">
                <p className="overview-paragraph">
                  {data[dataIndex]?.data?.description[0]?.text}
                </p>
              </div>
              <div className="col-lg-3">
                <a className="apply-btn" href={`mailTo:${recruitment}`}>
                  Apply Now
                </a>
              </div>
            </div>
          </div>
        )}
      </section>
    </>
  );
}

export default JobRole;
