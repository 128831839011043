import React from "react";
import images from "../../Constants/images";
import CSRBox from "./CSRBox";
import { Fade } from "react-reveal";
function Community({ csr }) {
  return (
    <section className="csr-community">
      <div className="container">
        <Fade bottom>
          <CSRBox
            csr={csr}
            reverse={false}
            imageURL={csr[0]?.data?.com_scholarship.url}
            imageALt={csr[0]?.data?.com_scholarship.alt}
            curvedLine={true}
            description="Scholarships for pharmacy students are financial awards to help
                cover educational expenses and support academic pursuits in the
                field of pharmacy."
            heading="Scholarships for Student of Pharmacy School"
          />
        </Fade>
        <Fade bottom>
          <CSRBox
            csr={csr}
            reverse={true}
            imageURL={csr[0]?.data?.com_internship.url}
            imageALt={csr[0]?.data?.com_internship.alt}
            curvedLine={false}
            description="Internships provide new pharmacy and chemistry graduates
          practical training, helping build their professional network and
          gain experience."
            heading="Internships for the new Graduates of Pharmacy and Chemistry"
          />
        </Fade>
        <Fade bottom>
          <CSRBox
            csr={csr}
            reverse={false}
            imageURL={csr[0]?.data?.com_donation.url}
            imageALt={csr[0]?.data?.com_donation.alt}
            curvedLine={true}
            heading="Rs. 2.5 Million Donation to Universities every year"
            description="This donation aims to promote education and create opportunities
          for students to excel in their academic pursuits."
          />
        </Fade>

        <Fade bottom>
          <CSRBox
            csr={csr}
            reverse={true}
            imageURL={csr[0]?.data?.com_hospital?.url}
            imageALt={csr[0]?.data?.com_hospital?.alt}
            curvedLine={false}
            heading="Rs. 10 Million Donations to hospitals of patient beds and
          medicine."
            description="This donation aims to support healthcare facilities and improve
          access to quality healthcare services in the community."
          />
        </Fade>
        <Fade bottom>
          <CSRBox
            csr={csr}
            reverse={false}
            imageURL={csr[0]?.data?.community_flood?.url}
            imageALt={csr[0]?.data?.community_flood?.alt}
            curvedLine={true}
            heading="Rs. 2.5 Million Donation to Rehabilitate Flood Victims"
            description="Himont donates Rs. 2.5 million to rehabilitate earthquake and
          flood victims, demonstrating its commitment to Corporate
          Responsibility and supporting disaster relief efforts."
          />
        </Fade>

        <Fade bottom>
          <CSRBox
            csr={csr}
            reverse={true}
            imageURL={csr[0]?.data?.community_medical_institute?.url}
            imageALt={csr[0]?.data?.community_medical_institute?.alt}
            curvedLine={false}
            heading="Free Diagnostic Camps for Poor Patients at Major Medical
          Institutes"
            description="Himont sponsors free diagnostic camps for poor patients at major
          medical institutes, providing access to quality healthcare
          services for underprivileged communities."
          />
        </Fade>
      </div>
    </section>
  );
}

export default Community;
