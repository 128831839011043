import React from "react";
import images from "../../Constants/images";
import Fade from "react-reveal";

function Videoplayer() {
  return (
    <section className="uplayer-bg">
      <div className="container">
        <div className="row">
          <Fade bottom>
            <div className="col-lg-12">
              <h2 className="uplayer-h1">Watch us on Youtube</h2>
            </div>
            <div className="col-lg-12">
              <iframe
                className="uplayer-iframe"
                src="https://www.youtube.com/embed/Nny9ymQ2h_k"
                title="YouTube video player"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowFullScreen
              ></iframe>
            </div>
          </Fade>
        </div>
      </div>
    </section>
  );
}

export default Videoplayer;
