import React from "react";
import Paragraphs from "../Common/Headings/Paragraphs";
import images from "../../Constants/images";
import { Fade } from "react-reveal";

function Achievements({
  heading,
  paragraphText,
  departmentName,
  departmentImage,
}) {
  return (
    <div className="col-lg-12">
      <div className="achievements">
        <div className="row">
          <div className="col-lg-6">
            <div className="achievement-box">
              <img
                className="achievement-logo"
                src={images.HG}
                alt="Himont"
                loading="lazy"
              />
              <h2 className="title">{heading}</h2>
              <Paragraphs paragraphText={paragraphText} />
              {/* <h6 className="name">{departmentName}</h6> */}
            </div>
          </div>
          <div className="col-lg-6">
            <img src={departmentImage} alt={heading} width="100%" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Achievements;
