import React from "react";
import Fade from "react-reveal/Fade";

function Hero({ about }) {
  return (
    <section className="about-bg">
      <div className="container">
        <div className="row">
          <div className="col-lg-2"></div>
          <Fade bottom>
            <div className="col-lg-8">
              <div className="text-center">
                <div style={{ position: "relative" }}>
                  <h1 className="main-heading">About Us.</h1>
                  <svg
                    width="222"
                    height="19"
                    viewBox="0 0 222 19"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      opacity="0.5"
                      d="M5 14C62.2714 8.35706 184.651 -0.439291 216 9.51884"
                      stroke="#CB343B"
                      strokeWidth="10"
                      strokeLinecap="round"
                    />
                  </svg>
                </div>
              </div>
            </div>
            <div className="col-lg-2"></div>
            <div className="col-lg-12">
              <p className="base-paragraph event-paragraph">
                For 3 decades, the Himont Group has been driven by a deep desire
                to improve lives, pioneering groundbreaking healthcare
                innovations that are transforming people's lives. We are
                innovators with a relentless passion for pushing the boundaries
                of what's possible in healthcare. Every day, we are inspired by
                the belief that we can make a profound difference in the world,
                and our unwavering commitment to this mission has propelled us
                to the forefront of our industry. Our transformative innovations
                have already touched countless lives, and we are constantly
                pushing ourselves to do more.
              </p>
            </div>

            <div className="col-lg-12">
              <img
                className="about-hero-img"
                src={about[0]?.data?.about_banner?.url}
                alt="no_img_found"
                width="100%"
                loading="lazy"
              />
            </div>
          </Fade>
        </div>
      </div>
    </section>
  );
}

export default Hero;
