import React from "react";
import { Fade } from "react-reveal";

function EventBox({ imageURL, imageAlt, eventName, setOpenOverlay }) {
  return (
    <Fade bottom>
      <div className="col-lg-6" onClick={() => setOpenOverlay(true)}>
        <div>
          <img
            className="events-img"
            src={imageURL}
            alt={imageAlt}
            loading="lazy"
          />
          <h2 className="event-h1">{eventName}</h2>
        </div>
      </div>
    </Fade>
  );
}

export default EventBox;
