import React from "react";

function ThreeDots() {
  return (
    <div className="three-dots-box">
      <div className="dot-circle"></div>
      <div className="dot-circle"></div>
      <div className="dot-circle"></div>
    </div>
  );
}

export default ThreeDots;
