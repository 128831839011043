import React from "react";
import { contactMail, telephone } from "../../../Constants/urls";

function FooterEnd() {
  return (
    <div className="col-lg-12">
      <div className="himont-spacebetween">
        <p className="himont-group">Himont Group - All Rights Reserved</p>
        <a
          className="himont-group himont-group-line"
          href="https://sinecure.xyz"
          rel="noreferrer"
          target="_blank"
        >
          Powered by Sinecure 🚀
        </a>
        <div className="display-flex">
          <a href={`mailTo:${contactMail}`}>
            <p className="himont-group" style={{ marginRight: "24px" }}>
              {contactMail}
            </p>
          </a>
          <a href={`tel:${telephone}`}>
            <p className="himont-group">{telephone}</p>
          </a>
        </div>
      </div>
    </div>
  );
}

export default FooterEnd;
