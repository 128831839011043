import React from "react";
import images from "../../../Constants/images";
import TopHeadingSection from "../../Common/Headings/TopHeadingSection";
import Role1 from "./Role1";
import { Fade } from "react-reveal";

function Role({ paddingBottom, data }) {
  return (
    <section
      className="role-bg"
      style={{ marginTop: "102px", paddingBottom: paddingBottom }}
    >
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <TopHeadingSection
              mainHeading="Current Openings"
              description="Our company's strength lies in fostering a collaborative environment where each team member's talent can thrive and individuality is celebrated. Recognizing our team as our most valuable asset, we cultivate a culture of openness and ease, empowering employees to express themselves and contribute to our collective vision and success."
            />
          </div>
        </div>
        <div className="product-spacing"></div>
        <div className="row product-box-section">
          <Role1 data={data} />
        </div>
      </div>
    </section>
  );
}

export default Role;
