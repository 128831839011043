import React, { useEffect, useRef } from "react";

function Counter({ count, text, spanDiv, spanText }) {
  const countersRef = useRef(null);
  const observerRef = useRef(null);

  useEffect(() => {
    const options = {
      rootMargin: "0px",
      threshold: 0.5,
    };

    observerRef.current = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          animateCounter(entry.target);
          observerRef.current.unobserve(entry.target);
        }
      });
    }, options);

    observerRef.current.observe(countersRef.current);

    return () => {
      observerRef.current.disconnect();
    };
  }, []);

  function animateCounter(element) {
    const countElements = element.querySelectorAll(".count");
    countElements.forEach((countElement) => {
      const targetCount = parseInt(countElement.getAttribute("data-count"));
      const duration = 1000;
      const easing = (t) => (t === 1 ? 1 : 1 - Math.pow(2, -10 * t));
      let startCount = 0;
      let previousTimestamp = null;

      const countUp = (timestamp) => {
        if (!previousTimestamp) previousTimestamp = timestamp;
        const elapsed = timestamp - previousTimestamp;
        if (elapsed >= duration) {
          countElement.textContent = targetCount.toLocaleString();
        } else {
          const progress = elapsed / duration;
          const currentValue = Math.floor(
            startCount + (targetCount - startCount) * easing(progress)
          );
          countElement.textContent = currentValue.toLocaleString();
          requestAnimationFrame(countUp);
        }
      };

      requestAnimationFrame(countUp);
    });
  }

  return (
    <div className="counter hist-main-div" ref={countersRef}>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <h5 className="hist-number count" data-count={count}>
          {count}
        </h5>
        {spanDiv ? <p className="hist-number">{spanText}</p> : <></>}
      </div>
      <h6 className="hist-exp">{text}</h6>
      {/* <h5 className="hist-number count" data-count="250">
        250 <span className="hist-number">+</span>
      </h5>
      <h6 className="hist-exp">Number of Employees</h6>
      
      <h5 className="hist-number count" data-count="32000">
        32000
      </h5>
      <h6 className="hist-exp">Pharmacies covered</h6>
      <h5 className="hist-number count" data-count="230">
        230
      </h5>
      <h6 className="hist-exp">No of Products</h6> */}
    </div>
  );
}

export default Counter;
