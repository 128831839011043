import React from "react";

import images from "../../../Constants/images";

function Group1({ home }) {
  return (
    <>
      <div className="row">
        <div className="center">
          <div className="col-lg-12">
            <img
              className="group-img"
              src={home[0]?.data?.group_picture?.url}
              alt="unable to load"
              loading="lazy"
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default Group1;
