import React from "react";
import {
  about,
  companyProfile,
  departments,
  globalSupplyChain,
  mediaAndEvents,
} from "../../../Constants/urls";

function FooterLinkTwo() {
  return (
    <div className="col-lg-2">
      <div className="justify-start">
        <a href={`/${about}`}>
          <p className="footer-pinpoint">About Us</p>
        </a>
        <a href={`/${companyProfile}`}>
          <p className="footer-pinpoint">Company Profile</p>
        </a>
        <a href={`/${globalSupplyChain}`}>
          <p className="footer-pinpoint">Global Supply Chain</p>
        </a>
        <a href={`/${departments}`}>
          <p className="footer-pinpoint">Departments</p>
        </a>
        <a href={`/${mediaAndEvents}`}>
          <p className="footer-pinpoint">Media & Events</p>
        </a>
      </div>
    </div>
  );
}

export default FooterLinkTwo;
