import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";

function ScrollToTop() {
  const location = useLocation();

  useEffect(() => {
    const handleScrollToTop = () => {
      if (
        location.pathname === "/csr" &&
        location.hash === "#Pharmacovigilance"
      ) {
        const section = document.getElementById("Pharmacovigilance");
        if (section) {
          section.scrollIntoView({ behavior: "smooth" });
        }
      } else {
        window.scrollTo(0, 0);
      }
    };

    handleScrollToTop();
  }, [location]);

  return null;
}

export default ScrollToTop;
