import React from "react";
import Paragraphs from "../Common/Headings/Paragraphs";
import { Fade } from "react-reveal";

function Description() {
  return (
    <section className="csr-text-section">
      <div className="container">
        <div className="row">
          <Fade bottom>
            <div className="col-lg-12">
              <Paragraphs
                paragraphText={`We have a strong commitment to corporate responsibility and a straightforward philosophy of wanting to serve and give back to our community and our country. We are thus at the forefront, extending our help to the medical community through donations for hospitals, universities, diagnostic camps, and the rehabilitation of natural disaster victims. Providing scholarships for students of pharmacy schools and internships for new graduates of pharmacy and chemistry has established Himont among the medical community as a responsible and professional partner. We now want to take up a major issue and help Pakistan " stop stunting among our children’ due to malnutrition and anaemia. As the first step towards our goal, we have developed a patient education and lifestyle modification film, ‘Kahin Der Na Ho Jaye ’. This educational film will raise awareness about this serious issue faced by our community.`}
                nameOfClass="c-description"
              />
            </div>
          </Fade>
        </div>
      </div>
    </section>
  );
}

export default Description;
