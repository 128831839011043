import React from "react";
import images from "../../../Constants/images";

function Group2() {
  return (
    <div className="row">
      <div className="center">
        <div className="col-lg-4">
          <img
            className="group-img"
            src={images.carrier3}
            alt="unable to load"
            loading="lazy"
          />
        </div>
        <div className="col-lg-8">
          <img
            className="group-img"
            src={images.carrier4}
            alt="unable to load"
            loading="lazy"
          />
        </div>
      </div>
    </div>
  );
}

export default Group2;
