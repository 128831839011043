import React from "react";
import { Link } from "react-router-dom";
import images from "../../Constants/images";
import { products } from "../../Constants/urls";
import Paragraphs from "../Common/Headings/Paragraphs";
import { Fade } from "react-reveal";

function Hero({ home }) {
  return (
    <section className="background-main">
      <div className="container-fluid">
        <Fade bottom>
          <div className="row reverse-on-mobile">
            <div className="col-lg-1"></div>
            <div className="col-lg-4 main-center">
              <div style={{ position: "relative" }}>
                <h1 className="main-heading">
                  Innovating for <br /> Better Health.
                </h1>
                <svg
                  width="291"
                  height="20"
                  viewBox="0 0 291 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    opacity="0.5"
                    d="M5.5 14.4998C81.5 8.83309 243.9 -0.000246048 285.5 9.99975"
                    stroke="#CB343B"
                    strokeWidth="10"
                    strokeLinecap="round"
                  />
                </svg>
              </div>

              <Paragraphs
                nameOfClass="main-paragraph"
                paragraphText="Driven by a desire to improve lives, Himont Group is transforming
                people's lives by pioneering groundbreaking healthcare
                innovations."
              />

              <Link to={`/${products}`} className="main-btn home-cta">
                Our Products
              </Link>
            </div>
            <div className="col-lg-6 landing-family">
              <img
                src={home[0]?.data?.banner_image?.url}
                alt={home[0]?.data?.banner_image?.alt}
                width="100%"
                height="100%"
                loading="lazy"
                style={{ borderRadius: "8px" }}
              />
            </div>
            <div className="col-lg-1"></div>
          </div>
        </Fade>
      </div>
      {/* <img src={images.HeroBannerImg} alt="hero" className="landing-hero-img" /> */}
    </section>
  );
}

export default Hero;
