import React from "react";
import images from "../../Constants/images";

function Group4() {
  return (
    <div className="row">
      <div className="center">
        <div className="col-lg-4">
          <img
            className="group-img"
            src={images.carrier7}
            alt="unable to load"
          />
        </div>
        <div className="col-lg-8">
          <img
            className="group-img"
            src={images.carrier8}
            alt="unable to load"
          />
        </div>
      </div>
    </div>
  );
}

export default Group4;
