import React from "react";
import { Fade } from "react-reveal";
import Paragraphs from "../Common/Headings/Paragraphs";

function Description() {
  return (
    <section className="research-second-section">
      <div className="container">
        <Fade bottom>
          <div className="row">
            <div className="col-lg-12">
              <Paragraphs
                nameOfClass="c-description"
                paragraphText=" Himont has established a state-of-the-art R&D structure that
                encourages ingenuity and facilitates the accelerated development
                of innovative medicines to meet the consumer needs. With the use
                of Genetics and Advanced technologies, a highly qualified and
                dedicated team of our product development and research
                department, backed up by the professional analytical development
                laboratory, formulate quality products through a strenuous
                process of scientific evaluation and skill. The research
                products under go trial production and thorough testing before
                they are approved and launched into the market, as we aim to
                command a leading position in healthcare products and their
                development"
              />
            </div>
          </div>
        </Fade>
      </div>
    </section>
  );
}

export default Description;
