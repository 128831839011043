import React from "react";

function DrawerToggleButton({ click }) {
  return (
    <button aria-label="toggle" className="toggle-button" onClick={click}>
      <div className="toggle-button__line"></div>
      <div className="toggle-button__line"></div>
      <div className="toggle-button__line"></div>
    </button>
  );
}

export default DrawerToggleButton;
