import React from "react";
import images from "../../Constants/images";
import TopHeadingSection from "../Common/Headings/TopHeadingSection";
import Fade from "react-reveal";

function ManufacturingDetail({ manufacture }) {
  return (
    <>
      <section className="manu-section-2">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <TopHeadingSection
                subText="Top Quality"
                mainHeading="Our Manufacturing Plant"
                description="The manufacturing facility was founded in 1994 with a modest portfolio of 2 to 3
                 products, but has since grown significantly to offer a vast range of over 230 products, with
                  ongoing expansion. Himont Group prides itself on its cutting-edge, environmentally friendly
                   production facility, overseen by highly skilled experts. Additionally, the facility houses
                    a dedicated Quality Control department and a well-equipped laboratory, demonstrating our 
                    unwavering commitment to upholding international standards and guidelines for maintaining 
                    superior quality."
              />
              <Fade bottom>
                <div className="manufacturing-card">
                  <div className="text-img">
                    <img
                      className="manu-img"
                      src={manufacture[0]?.data?.manufacturing_plant?.url}
                      alt="card"
                      loading="lazy"
                    />
                  </div>
                </div>
              </Fade>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default ManufacturingDetail;
