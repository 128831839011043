import React, { useEffect, useState } from "react";
import Hero from "./Hero";
import Sectors from "./Sectors";
import Contact from "../Common/Contact/Contact";
import Footer from "../Common/Footer/Footer";
import { useAllPrismicDocumentsByType } from "@prismicio/react";
import Loader from "../Common/Loader";

function Department() {
  const [data] = useAllPrismicDocumentsByType("department");
  const [loader, setLoader] = useState(false);
  useEffect(() => {
    document.title = "Departments | Himont";
    setTimeout(() => {
      setLoader(true);
    }, 1500);
  }, [loader]);
  if (!loader) {
    return <Loader />;
  }

  return (
    <>
      {data === undefined ? (
        <Loader />
      ) : (
        <>
          <Hero />
          <Sectors department={data} />
          <Contact />
          <Footer />
        </>
      )}
    </>
  );
}

export default Department;
