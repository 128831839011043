import React, { useState } from "react";
import images from "../../Constants/images";
import Paragraphs from "../Common/Headings/Paragraphs";
import { useAllPrismicDocumentsByType } from "@prismicio/react";
import EventBox from "./EventBox";
import Fade from "react-reveal";

function Event() {
  //
  //
  const [event] = useAllPrismicDocumentsByType("event");
  const [counter, setCounter] = useState(3);
  const [openOverlay, setOpenOverlay] = useState(false);
  const [visible, setVisible] = useState(2);

  // function mapFunction(event) {
  //   for (let i = 0; i < counter; i++) {
  //     return (
  //       <EventBox
  //         imageURL={event[i]?.data?.images[0]?.img?.url}
  //         imageAlt={event[i]?.data?.images[0]?.img?.alt}
  //         eventName={event[1]?.data?.name[0]?.text}
  //       />
  //     );
  //   }
  // }

  const loadMore = () => {
    setVisible(visible + 2);
  };

  return (
    <>
      <div
        className={`overlay-events ${
          openOverlay ? "open-events" : "close-events"
        }`}
        onClick={() => setOpenOverlay(false)}
      ></div>
      <section className="event">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <Fade bottom>
                <h2 className="product-h2">
                  Our Events <span className="dot">.</span>
                </h2>
                <Paragraphs
                  nameOfClass="product-paragraph"
                  paragraphText="Himont Group's dedicated focus on employee training 
                  and development ensures continual improvement and organizational 
                  success across all divisions. Relentless training and development 
                  will empower our employees, ensuring the collective growth of the 
                  company and its people. This commitment is the essence of Himont 
                  Group's excellence and the foundation of our success."
                />
              </Fade>
            </div>
          </div>
          <div className="row" style={{ marginTop: "40px" }}>
            {event?.slice(0, visible).map((i, index) => {
              return (
                <EventBox
                  key={index}
                  imageURL={i.data?.images[0]?.img?.url}
                  imageAlt={i.data?.images[0]?.img?.alt}
                  eventName={i?.data?.name[0]?.text}
                  setOpenOverlay={setOpenOverlay}
                />
              );
            })}
          </div>
        </div>
        <div className="container">
          <div className="row">
            <div className="col-lg-12 center">
              {visible < event?.length && (
                <button className="load-more" onClick={loadMore}>
                  Load More
                </button>
              )}
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Event;
