import React from "react";
import images from "../../Constants/images";
import Paragraphs from "../Common/Headings/Paragraphs";
import Fade from "react-reveal";
function Hero() {
  return (
    <section className="company-profile">
      <div className="container cp-height-100">
        <div className="row cp-height-100">
          <Fade bottom>
            <div className="col-lg-6 cp-center">
              <div style={{ position: "relative" }}>
                <h1 className="main-heading">Himont Group.</h1>
                <svg
                  width="302"
                  height="17"
                  viewBox="0 0 302 17"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    opacity="0.5"
                    d="M5.00003 12C84.2572 7.61105 253.617 0.76944 297 8.51465"
                    stroke="#CB343B"
                    strokeWidth="10"
                    strokeLinecap="round"
                  />
                </svg>
              </div>
              <Paragraphs
                nameOfClass="c-main-paragraph"
                paragraphText="At Himont, we're dedicated to making a difference in people's
              lives by developing innovative healthcare solutions."
              />
            </div>
          </Fade>
        </div>
      </div>
    </section>
  );
}

export default Hero;
