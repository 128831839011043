import React from "react";
import Paragraphs from "./Paragraphs";
import Fade from "react-reveal";

function BriefHeading({
  subText,
  mainHeading,
  description,
  description1,
  description2,
}) {
  return (
    <>
      <Fade bottom>
        <h2 className="product-h2">
          {mainHeading}
          {/* <span className="dot">.</span> */}
        </h2>
        <Paragraphs
          nameOfClass="product-paragraph"
          paragraphText={description}
        />
        <Paragraphs
          nameOfClass="product-paragraph"
          paragraphText={description1}
        />
        <Paragraphs
          nameOfClass="product-paragraph"
          paragraphText={description2}
        />
      </Fade>
    </>
  );
}

export default BriefHeading;
