import React from "react";
import Paragraphs from "../Common/Headings/Paragraphs";

function PolicyCircle({ imageURL, altText, heading, paragraph }) {
  return (
    <div className="align-content">
      <div className="rounded-pol">
        <img
          className="inside-img"
          src={imageURL}
          alt={altText}
          loading="lazy"
        />
      </div>
      <h6 className="policy-heading1">{heading}</h6>
      <Paragraphs nameOfClass="policy-paragraph" paragraphText={paragraph} />
    </div>
  );
}

export default PolicyCircle;
