import React from "react";
import images from "../../Constants/images";
import Paragraphs from "../Common/Headings/Paragraphs";
import Fade from "react-reveal";

function Banner({ manufacture }) {
  return (
    <>
      <section className="manu-section">
        <div className="container">
          <Fade bottom>
            <div className="row">
              <div className="col-lg-4">
                <div className="text-center">
                  <div style={{ position: "relative" }}>
                    <h2 className="main-heading manufacturing-align">
                      Manufacturing Facility.
                    </h2>
                    <svg
                      className="our-products-svg"
                      height="21"
                      viewBox="0 0 376 21"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        opacity="0.5"
                        d="M5 16C104.071 9.10307 315.771 -1.64802 370 10.523"
                        stroke="#CB343B"
                        strokeWidth="10"
                        strokeLinecap="round"
                      />
                    </svg>
                  </div>
                </div>
              </div>
              <div className="col-lg-12">
                <Paragraphs
                  nameOfClass="event-paragraph manufacturing-text manufacturing-align"
                  paragraphText="Himont's emphasis lies in state-of-the-art, eco-friendly
                     production facility managed by skilled professionals. Our goal is to 
                     manufacture top-quality, health-focused products by implementing international
                      pharmaceutical industry standards and global best practices, enabling us to meet
                       international benchmarks and export opportunities. This commitment is the essence
                        of Himont Group's excellence and the foundation of our success."
                />
              </div>
            </div>
          </Fade>
        </div>
        <Fade bottom>
          <div className="manu-slider">
            <div className="container">
              <div className="row">
                <div className="col-lg-12 ">
                  <img
                    className="manu-img"
                    src={manufacture[0]?.data?.manufacturing_banner_gif?.url}
                    alt="manufacturing-plant"
                    loading="lazy"
                  />
                </div>
              </div>
            </div>
          </div>
        </Fade>
      </section>
    </>
  );
}

export default Banner;
