import React from "react";
import Fade from "react-reveal";

function TimelineBox({ year, title, description, side }) {
  return (
    <Fade bottom>
      <div className="row">
        {side === "right" ? <div className="col-lg-6"></div> : ""}
        <div className="col-lg-6 timeline-padding">
          <div
            className={`timeline-full ${
              side === "right" ? "timeline-end" : ""
            }`}
          >
            <div className="timeline-box">
              <h6 className="year">{year}</h6>
              <h4 className="milestone">{title}</h4>
              <p className="description">{description}</p>
            </div>
            <div
              className={`spot ${
                side === "right" ? "spot-right" : "spot-left"
              }`}
            ></div>
          </div>
        </div>
      </div>
    </Fade>
  );
}

export default TimelineBox;
