import React from "react";
import images from "../../Constants/images";
import QualityBox from "./QualityBox";
import Paragraphs from "../Common/Headings/Paragraphs";
import Fade from "react-reveal";

function QualitySection({ quality }) {
  return (
    <section className="qm-section">
      <div className="container">
        <Fade bottom>
          <QualityBox
            quality={quality}
            reverse={false}
            imageSRC={quality[0]?.data?.quality_img1?.url}
            imageAlt="Our Commitment"
            heading="Our Commitment"
            paragraph={[
              `At Himont, we prioritize quality excellence and continuous
          improvement through:`,
            ]}
            points={[
              `Strict adherence to Best Sustainable Manufacturing Practices to
          ensure product safety and efficacy.`,
              `Zero defects through a proactive agile approach and continuous
            improvement daily.`,
              `Full compliance with regulatory requirements and internal
            standards.`,
              `Fostering a culture of excellence, best-in-class laboratories
            and engagement across all functions of the organization.`,
            ]}
          />
        </Fade>
        <Fade bottom>
          <QualityBox
            quality={quality}
            reverse={true}
            imageSRC={quality[0]?.data?.quality_img2?.url}
            imageAlt="Inclusion and Diversity"
            heading="Inclusion & Diversity"
            paragraph={[
              "At our core, we recognize that our strength lies in the diversity of the communities we serve. That's why we've built an inclusive environment founded on metrics that prioritize acceptance and policies that champion tolerance.",
              "At our organization, every employee's unique identity is celebrated and cherished. We are proud to have a recruitment and reward system that values individuals for who they are, regardless of their gender, disability, sexual orientation, cultural background, or religious beliefs. To preserve and nurture this culture of acceptance, we conduct regular diversity training that equips our workforce with the tools and knowledge to promote inclusivity and celebrate differences.",
            ]}
            points={[]}
          />
        </Fade>
        <Fade bottom>
          <QualityBox
            quality={quality}
            reverse={false}
            imageSRC={quality[0]?.data?.quality_img3?.url}
            imageAlt="Sustainability"
            heading="Sustainability"
            paragraph={[
              "While maintaining the highest quality, we as responsible corporate citizens, are dedicated to bettering the planet's health and expanding our operations sustainably. Our environmental sustainability policies embody the Compass' ambitions, such as mitigating climate change, regenerating nature, and conserving resources for future generations. Together, we are forging a path towards a brighter future, propelled by our team's varied perspectives and shared passion for excellence.",
            ]}
            points={[]}
          />
        </Fade>
        <div className="col-lg-12"></div>
      </div>
      <div className="container">
        <div className="row">
          <Fade bottom>
            <div className="col-lg-12">
              <h2 className="product-h2 text-center">
                Health, Safety & Environment<span className="dot">.</span>
              </h2>

              <Paragraphs
                nameOfClass="qm-justify-text"
                paragraphText="At Himont, we take safety management and cultivating a safety-first culture very seriously.  We recognize that our company's robustness is rooted in our unwavering commitment to nurturing a team-oriented and safe culture that values diversity and encourages our talent to reach their full potential."
              />
              <Paragraphs
                nameOfClass="qm-justify-text"
                paragraphText="We place our utmost faith in our team, as they are the driving force behind our accomplishments. By creating a work environment that champions openness, creativity, and unrestricted expression, we empower our staff to flourish and unlock their true potential."
              />
            </div>
          </Fade>
        </div>
      </div>
    </section>
  );
}
export default QualitySection;
