import React from "react";

function Box({ title, imgURL, altText }) {
  return (
    <div className="box-detail">
      <img className="box-img" src={imgURL} alt={altText} loading="lazy" />
      <h4 className="mission-heading3">{title}</h4>
    </div>
  );
}

export default Box;
