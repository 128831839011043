import React from "react";
import images from "../../Constants/images";
import Paragraphs from "../Common/Headings/Paragraphs";
import SectorsBox from "./SectorsBox";
import { Fade } from "react-reveal";
import TopHeadingSection from "../Common/Headings/TopHeadingSection";
function Sectors({ department }) {
  return (
    <section className="research-text-section">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <TopHeadingSection mainHeading="Departments" />
          </div>
        </div>
        <Fade bottom>
          <SectorsBox
            reverse={false}
            imageURL={department[0]?.data?.dept_sales?.url}
            imageAlt={department[0]?.data?.dept_sales?.alt}
            iconURL={department[0]?.data?.dept_sales_icon?.url}
            iconAlt={department[0]?.data?.dept_sales_icon?.alt}
            departmentName="Sales"
            departmentParagraph="Himont Group has pioneered the application of high-quality manufacturing practices with international grade medicines in Pakistan. Currently, we are one of the top pharmaceutical company in Pakistan with a wide range of quality products covering major therapeutic areas including Gastroenterology, CVRM (Cardiometabolic, Renal & Metabolism), Obstetrics, Respiratory, Nutrition, Gynecology, Dermatology, Pediatrics."
          />
        </Fade>

        <Fade bottom>
          <SectorsBox
            reverse={true}
            imageURL={department[0]?.data?.dept_marketing?.url}
            imageAlt={department[0]?.data?.dept_marketing?.alt}
            iconURL={department[0]?.data?.dept_marketing_icon?.url}
            iconAlt={department[0]?.data?.dept_marketing_icon?.alt}
            departmentName="Marketing"
            departmentParagraph="At Himont Group, our Marketing Department takes a forward-thinking approach to modernizing our techniques while still valuing traditional methods. We proactively explore cutting-edge approaches and closely collaborate with our sales department to ensure product availability, staying ahead of the curve to meet and exceed our customers' needs and expectations."
          />
        </Fade>
        <Fade bottom>
          <SectorsBox
            reverse={false}
            imageURL={department[0]?.data?.dept_finance?.url}
            imageAlt={department[0]?.data?.dept_finance?.alt}
            iconURL={department[0]?.data?.dept_finance_icon?.url}
            iconAlt={department[0]?.data?.dept_finance_icon?.alt}
            departmentName="Finance"
            departmentParagraph="The finance department of himont group provides timely and accurate financial information to key stakeholders while protecting the group's assets, both real and intangible, as well as ensuring himont's compliance to local tax laws from a financial and fiduciary vantage point. therefore it serves as an indispensable partner on areas requiring economic, financial and fiduciary inputs and expertise."
          />
        </Fade>
        <Fade bottom>
          <SectorsBox
            reverse={true}
            imageURL={department[0]?.data?.dept_hr?.url}
            imageAlt={department[0]?.data?.dept_hr?.alt}
            iconURL={department[0]?.data?.dept_hr_icon?.url}
            iconAlt={department[0]?.data?.dept_hr_icon?.alt}
            departmentName="HR"
            departmentParagraph="Our endeavor to improve ourselves starts with the improvement of the total workforce of Himont. All the ideas for the future will ultimately come from and will be carried out by these people. Therefore, they should be well trained in their craft. We have a performance-driven culture that is based on trust, accountability, and learning. Himont Group provides an excellent work-life balance to its employees with an exceptional diversity and inclusion percentage. We have a zero-tolerance policy to protect our female staff. For employee concerns and effective communication, we have a speak-up process in place for all our employees."
          />
        </Fade>
        <Fade bottom>
          <SectorsBox
            reverse={false}
            imageURL={department[0]?.data?.dept_supply?.url}
            imageAlt={department[0]?.data?.dept_supply?.alt}
            iconURL={department[0]?.data?.dept_supplychain_icon?.url}
            iconAlt={department[0]?.data?.dept_supplychain_icon?.alt}
            departmentName="Supply Chain"
            departmentParagraph="Himont Group boasts a proactive and efficient supply chain, operating seamlessly across seven countries with a diverse portfolio of over 300 products. Our team comprises over 100 dynamic individuals who are committed to excellence and consistently exceed expectations. Our mature and established supply chain serves as a strong foundation as we expand our operations on a global scale. Our passion and unwavering commitment to excellence drive us forward, propelling us to new heights of success."
          />
        </Fade>
        <Fade bottom>
          <SectorsBox
            reverse={true}
            imageURL={department[0]?.data?.dept_production?.url}
            imageAlt={department[0]?.data?.dept_production?.alt}
            iconURL={department[0]?.data?.dept_production_icon?.url}
            iconAlt={department[0]?.data?.dept_production_icon?.alt}
            departmentName="Production"
            departmentParagraph="We always embrace cutting-edge technology to produce healthcare products that meet international standards, working relentlessly towards making a lasting impact on society, pioneering groundbreaking healthcare innovations. It is our mission to ensure that the equipment or machinery employed in the production process of high-quality medicine is of topmost quality. We continuously invest in state-of-the-art equipment and upgrade our production facilities to align with evolving technological advancements and adhere to top notch manufacturing practices."
          />
        </Fade>
        <Fade bottom>
          <SectorsBox
            reverse={false}
            imageURL={department[0]?.data?.dept_it?.url}
            imageAlt={department[0]?.data?.dept_it?.alt}
            iconURL={department[0]?.data?.dept_it_icon?.url}
            iconAlt={department[0]?.data?.dept_it_icon?.alt}
            departmentName="Information Technology, IT"
            departmentParagraph="The adoption of cutting-edge technology is critical to realizing our future vision. Himont recognizes the importance of this and is continually leveraging our IT team's expertise to manufacture top-quality, health-focused products that adhere to pharmaceutical industry standards and global best practices. Through our IT team's efforts, we are able to achieve international benchmarks, capitalize on export opportunities, and remain fully compliant with cybersecurity and internet regulations, all while maintaining an effective management information system. In doing so, we position ourselves as a smart digital organization and underscore our commitment to excellence."
          />
        </Fade>
      </div>
    </section>
  );
}

export default Sectors;
