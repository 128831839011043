import React from "react";
import images from "../../../Constants/images";
import Box from "./Box";
import MissionHeading from "../../Common/Headings/MissionHeading";
import Fade from "react-reveal";

function MissionSection({ home }) {
  return (
    <section className="mission-background">
      <div className="container-fluid">
        <Fade bottom>
          <div className="row">
            <div className="col-lg-1"></div>
            <div className="col-lg-5">
              <MissionHeading
                page="main"
                subText="What we want to do"
                mainHeading="Our Mission."
                description="A world in which the privilege of exceptional healthcare products becomes a fundamental right, accessible and attainable for everyone, transcending all boundaries, by revolutionizing health and well-being through the provision of exceptional and innovative products."
              />
            </div>
            <div className="col-lg-6 rotate">
              <div className="row">
                <div className="col-lg-6 center box-end">
                  <Box
                    title="Accessibility and Affordability"
                    imgURL={home[0]?.data?.mission_img_1?.url}
                    altText={home[0]?.data?.mission_img_1?.alt}
                  />
                </div>
                <div className="col-lg-6 center box-start">
                  <Box
                    title="Patient Centric Care & Compassion"
                    imgURL={home[0]?.data?.mission_img_2?.url}
                    altText={home[0]?.data?.mission_img_2?.alt}
                  />
                </div>
                <div className="col-lg-6 center box-end">
                  <Box
                    title="Research and Great Innovation"
                    imgURL={home[0]?.data?.mission_img_3?.url}
                    altText={home[0]?.data?.mission_img_3?.alt}
                  />
                </div>
                <div className="col-lg-6 center box-start">
                  <Box
                    title="Quality and Safety Standards"
                    imgURL={home[0]?.data?.mission_img_4?.url}
                    altText={home[0]?.data?.mission_img_4?.alt}
                  />
                </div>
              </div>
            </div>
          </div>
        </Fade>
      </div>
    </section>
  );
}

export default MissionSection;
