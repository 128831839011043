import React from "react";
import images from "../../Constants/images";
import Paragraphs from "../Common/Headings/Paragraphs";
import Fade from "react-reveal";

function QM_Banner({ quality }) {
  return (
    <section className="csr csr-bg">
      <div className="container">
        <Fade bottom>
          <div className="row">
            <div className="col-lg-6 c-main-center">
              <div style={{ position: "relative" }}>
                <h1 className="main-heading">Quality Management</h1>
                <svg
                  className="our-products-svg"
                  height="23"
                  viewBox="0 0 449 23"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    opacity="0.5"
                    d="M5 18C124.157 9.84909 378.777 -2.85675 444 11.5272"
                    stroke="#CB343B"
                    strokeWidth="10"
                    strokeLinecap="round"
                  />
                </svg>
              </div>

              <Paragraphs
                nameOfClass="c-main-paragraph"
                paragraphText="At Himont Group, we live and breathe the ethos of breaking barriers and redefining the limits of medical advancement. Our cutting-edge facility boasts a state-of-the-art analytical laboratory that adheres to international standards, guaranteeing utmost precision and accuracy. We spare no expense in nurturing our workforce, refining our systems and bolstering our infrastructure, all to deliver top-quality products that cultivate ingenuity and propel innovation throughout every department."
              />
            </div>

            <div className="col-lg-6 text-center">
              <img
                src={quality[0]?.data?.quality_banner?.url}
                alt="quality-management"
                loading="lazy"
                width="100%"
              />
            </div>
          </div>
        </Fade>
      </div>
    </section>
  );
}

export default QM_Banner;
