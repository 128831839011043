import React from "react";
import Paragraphs from "../Common/Headings/Paragraphs";
import ThreeDots from "../Common/ThreeDots";

function QualityBox({
  imageSRC,
  imageAlt,
  heading,
  paragraph,
  points,
  reverse,
  quality,
}) {
  return (
    <div className={`row qm-box ${reverse ? "qm-reverse" : ""}`}>
      <div className="col-lg-6 qm-image-center">
        <img
          className="qm-image"
          src={imageSRC}
          alt={imageAlt}
          loading="lazy"
        />
      </div>
      <div className="col-lg-6 qm-align-section">
        <ThreeDots />

        <h2 className="qm-section-heading">
          {heading}
          <span className="color-red"></span>.
        </h2>
        {paragraph?.length > 0 ? (
          <>
            {paragraph.map((i, index) => (
              <Paragraphs
                key={index}
                nameOfClass="qm-color qm-justify-text"
                paragraphText={i}
              />
            ))}
          </>
        ) : (
          <></>
        )}
        {points.length > 0 ? (
          <ul>
            {points.map((i, index) => (
              <li key={index}>
                <Paragraphs nameOfClass="qm-color" paragraphText={i} />
              </li>
            ))}
          </ul>
        ) : (
          <></>
        )}

        <ThreeDots />
      </div>
    </div>
  );
}

export default QualityBox;
