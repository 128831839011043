import React from "react";
import images from "../../Constants/images";
import BriefHeading from "../Common/Headings/BriefHeading";
import Group1 from "../Main/Group/Group1";
import Group2 from "../Main/Group/Group2";
import Group3 from "./Group3";
import Group4 from "./Group4";
import { Fade } from "react-reveal";

function Himontinsides({ paddingBottom }) {
  let pathname = window.location.pathname.slice(1);
  return (
    <section
      style={{
        marginTop: "102px",
        marginBottom: "80PX",
        paddingBottom: paddingBottom,
      }}
    >
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <BriefHeading
              subText="A brief look"
              mainHeading="Our Culture"
              description="We have the pride to be one of the most diversified healthcare companies. At Himont, we take immense pride in being a people-centric and goal-oriented organization that thrives in the market place. Our success is rooted in the values that we live by - integrity, collaboration, innovation, and passion for creating healthy and happy lives. "
              description1="Innovation is at the heart of our growth, and we foster a culture of creativity and experimentation that provides ample space for our team members to develop and grow. We are always seeking better ways to create solutions and treatments that improve healthcare outcomes, while upholding the highest standards of transparency and ethical conduct."
              description2="At Himont, we believe that our solutions are only as good as the minds that develop and empower them. That's why we invest heavily in the development of our team members, enabling them to unleash their full potential and contribute to our mission of improving lives."
            />
          </div>
        </div>
        {/* <div className="product-spacing"></div> */}
      </div>

      <div className="container">
        {pathname === "about" ? (
          <div className="row about-video">
            <div className="col-lg-12">
              <iframe
                className="uplayer-iframe"
                src="https://www.youtube.com/embed/Nny9ymQ2h_k"
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
            </div>
          </div>
        ) : (
          <div className="row">
            <div className="col-lg-12  group-box" style={{ marginTop: "32px" }}>
              <Group1 />
            </div>
            <div className="col-lg-12  group-box">
              <Group2 />
            </div>
            <div className="col-lg-12  group-box">
              <Group3 />
            </div>
            <div className="col-lg-12  group-box">
              <Group4 />
            </div>
          </div>
        )}
      </div>
    </section>
  );
}

export default Himontinsides;
