import React, { useState } from "react";
import TopHeadingSection from "../Headings/TopHeadingSection";
import images from "../../../Constants/images";
import ContactForm from "./ContactForm";
import { contactMail, telephone } from "../../../Constants/urls";
import { Fade } from "react-reveal";
import Loader from "../Loader";

function Contact() {
  const [isLoading, setLoading] = useState(false);
  return (
    <section className="contact-section">
      {isLoading && <Loader />}
      <div className="container">
        <Fade bottom>
          <div className="row">
            <div className="col-lg-12">
              <div className="contact-box">
                <div className="row no-padding">
                  <div className="col-lg-6">
                    <TopHeadingSection
                      mainHeading="Contact"
                      description="We are excited to hear from you"
                    />
                    <ContactForm setLoading={setLoading} />
                    <div className="more-box">
                      <a href={`tel:${telephone}`} className="contact-area">
                        <svg
                          className="contact-icon"
                          width="20"
                          height="20"
                          viewBox="0 0 20 20"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M5.65436 3.32826C5.59532 3.25233 5.52081 3.18982 5.43577 3.14489C5.35072 3.09997 5.25709 3.07365 5.16109 3.06769C5.06509 3.06173 4.96893 3.07627 4.87898 3.11033C4.78903 3.1444 4.70736 3.19721 4.63939 3.26526L3.60542 4.30024C3.12244 4.78423 2.94444 5.46921 3.15543 6.0702C4.03115 8.55772 5.45566 10.8162 7.3233 12.678C9.18517 14.5457 11.4436 15.9702 13.9311 16.8459C14.5321 17.0569 15.2171 16.8789 15.701 16.3959L16.735 15.362C16.8031 15.294 16.8559 15.2123 16.8899 15.1224C16.924 15.0324 16.9385 14.9363 16.9326 14.8403C16.9266 14.7443 16.9003 14.6506 16.8554 14.5656C16.8105 14.4805 16.7479 14.406 16.672 14.347L14.3651 12.553C14.284 12.4901 14.1896 12.4464 14.0891 12.4253C13.9887 12.4042 13.8847 12.4061 13.7851 12.431L11.5952 12.978C11.3029 13.0511 10.9966 13.0472 10.7062 12.9668C10.4159 12.8863 10.1513 12.7321 9.93822 12.519L7.4823 10.0621C7.26907 9.84914 7.11464 9.58459 7.03403 9.29422C6.95342 9.00384 6.94938 8.69754 7.02231 8.40514L7.5703 6.21519C7.5952 6.11559 7.59716 6.01163 7.57603 5.91116C7.5549 5.81069 7.51122 5.71633 7.4483 5.63521L5.65436 3.32826ZM3.88441 2.51128C4.0594 2.33624 4.26963 2.20042 4.50113 2.11285C4.73264 2.02529 4.98012 1.98797 5.22715 2.00339C5.47418 2.0188 5.71511 2.0866 5.93393 2.20227C6.15275 2.31794 6.34446 2.47883 6.49633 2.67428L8.29027 4.98022C8.61926 5.40321 8.73526 5.9542 8.60526 6.47419L8.05828 8.66413C8.03 8.77756 8.03153 8.89637 8.06272 9.00903C8.0939 9.1217 8.15369 9.22438 8.23628 9.30712L10.6932 11.7641C10.776 11.8468 10.8789 11.9067 10.9917 11.9379C11.1046 11.9691 11.2236 11.9705 11.3372 11.9421L13.5261 11.3951C13.7827 11.3309 14.0506 11.3259 14.3094 11.3805C14.5682 11.4351 14.8112 11.5478 15.0201 11.7101L17.326 13.504C18.155 14.149 18.231 15.374 17.489 16.1149L16.455 17.1489C15.715 17.8889 14.6091 18.2139 13.5781 17.8509C10.9394 16.9225 8.54354 15.4118 6.56833 13.431C4.58768 11.4561 3.07705 9.06058 2.14847 6.42219C1.78648 5.39221 2.11147 4.28524 2.85144 3.54526L3.88541 2.51128H3.88441Z"
                            fill="#CB343B"
                          />
                        </svg>

                        <p className="contact-info">{telephone}</p>
                      </a>
                      <a
                        href={`mailTo:${contactMail}`}
                        className="contact-area"
                      >
                        <svg
                          className="contact-icon"
                          width="20"
                          height="20"
                          viewBox="0 0 20 20"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M18.3337 5.00016C18.3337 4.0835 17.5837 3.3335 16.667 3.3335H3.33366C2.41699 3.3335 1.66699 4.0835 1.66699 5.00016V15.0002C1.66699 15.9168 2.41699 16.6668 3.33366 16.6668H16.667C17.5837 16.6668 18.3337 15.9168 18.3337 15.0002V5.00016ZM16.667 5.00016L10.0003 9.16683L3.33366 5.00016H16.667ZM16.667 15.0002H3.33366V6.66683L10.0003 10.8335L16.667 6.66683V15.0002Z"
                            fill="#CB343B"
                          />
                        </svg>
                        <p className="contact-info">{contactMail}</p>
                      </a>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="contact-img-box">
                      <img
                        src={images?.contactImage}
                        className="contact-img"
                        alt="contact-img"
                        loading="lazy"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Fade>
      </div>
    </section>
  );
}

export default Contact;
