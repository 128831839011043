import React from "react";
import {
  consumer,
  herbal,
  nutraceuticals,
  pharma,
  skinCare,
  products,
} from "../../../Constants/urls";
import { Link } from "react-router-dom";

function FooterLinkOne() {
  return (
    <div className="col-lg-2">
      <div className="justify-start">
        <Link to="/">
          <p className="footer-pinpoint">Home</p>
        </Link>
        <Link to={`/${products}`}>
          <p className="footer-pinpoint">Pharma</p>
        </Link>
        <a href={`/${products}`}>
          <p className="footer-pinpoint">Herbal</p>
        </a>
        <Link to={`/${products}`}>
          <p className="footer-pinpoint">Skin Care</p>
        </Link>
        <Link to={`/${products}`}>
          <p className="footer-pinpoint">Consumer</p>
        </Link>
      </div>
    </div>
  );
}

export default FooterLinkOne;
