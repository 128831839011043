import React from "react";
import Paragraphs from "./Paragraphs";
import Fade from "react-reveal";

function MissionHeading({
  mainHeading,
  description,
  detail1,
  detail2,
  detail3,
  detail4,
  page,
}) {
  return (
    <>
      <Fade bottom>
        <div className="mission-alignment">
          <h2 className="mission-heading2">{mainHeading}</h2>

          <Paragraphs
            nameOfClass="mission-paragraph"
            paragraphText={description}
          />
          {page == "about" && (
            <ul>
              <li style={{ color: "white" }}>
                <p className="base-paragraph mission-paragraph">{detail4}</p>
              </li>
              <li style={{ color: "white" }}>
                <p className="base-paragraph mission-paragraph">{detail1}</p>
              </li>
              <li style={{ color: "white" }}>
                <p className="base-paragraph mission-paragraph">{detail2}</p>
              </li>
              <li style={{ color: "white" }}>
                <p className="base-paragraph mission-paragraph">{detail3}</p>
              </li>
            </ul>
          )}
        </div>
      </Fade>
    </>
  );
}

export default MissionHeading;
