import React from "react";

function FooterSocialMedia({ images }) {
  return (
    <div className="col-lg-2">
      <div className="justify-start">
        <div className="company-logo">
          <img className="company-img" src={images.HG} alt="" />
        </div>
        {/* <h5 className="follow">Follow Us</h5> */}
      </div>
    </div>
  );
}

export default FooterSocialMedia;
