import React from "react";
import images from "../../Constants/images";
import Fade from "react-reveal";
import Paragraphs from "../Common/Headings/Paragraphs";

function Banner({ global }) {
  return (
    <section className="supply-chain-bg csr-bg">
      <div className="container">
        <Fade bottom>
          <div className="row">
            <div className="col-lg-6 c-main-center">
              <div style={{ position: "relative" }}>
                <h1 className="main-heading">Global Supply Chain</h1>
                <svg
                  className="our-products-svg"
                  width="458"
                  height="19"
                  viewBox="0 0 458 19"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    opacity="0.5"
                    d="M5 14C126.6 8.35706 386.44 -0.439291 453 9.51884"
                    stroke="#CB343B"
                    strokeWidth="10"
                    strokeLinecap="round"
                  />
                </svg>
              </div>
              <Paragraphs
                nameOfClass="c-main-paragraph"
                paragraphText="Himont’s Supply Chain is an integral part of our company's operations,
                spanning across 7 countries and offering a diverse portfolio of
                over 230 products. Our team of over 250 passionate and dynamic
                individuals is committed to driving growth and excellence in
                everything we do."
              />
              <Paragraphs
                paragraphText="And that's not all - we're also
                proud to announce that Himont’s Supply Chain is poised to expand even
                further on the global stage. With the unwavering dedication of
                our Sales and Marketing teams, we're well on our way to
                realizing Searle's corporate vision of improving the quality of
                human life."
                nameOfClass="c-main-paragraph"
              />
            </div>
            <div className="col-lg-6">
              <img
                className="global-supply-gif"
                src={global[0]?.data.gsc_hero_banner?.url}
                alt="global_banner"
                loading="lazy"
              />
            </div>
          </div>
        </Fade>
      </div>
    </section>
  );
}

export default Banner;
