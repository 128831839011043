import React from "react";
import images from "../../../Constants/images";

function Product1({
  url,
  alt,
  setSeeMore,
  setParagraphOne,
  setParagraphTwo,
  setHeading,
}) {
  const openLink = () => {
    window.open(
      "https://himont.com/shop/product-category/pharmaceutical-products/",
      "_blank"
    );
  };
  return (
    <>
      <div className="col-lg-7">
        <div className="product-section pink-bg">
          <div className="circle-lg light-pink-bg circle-lg-right"></div>
          <div className="circle-box light-pink-bg">
            <img className="product-img" src={url} alt={alt} loading="lazy" />
          </div>
          <div className="circle-sm light-pink-bg circle-sm-right"></div>
        </div>
      </div>

      <div className="col-lg-5 product-center">
        <div className="product-info-box pink-border right-product-info">
          <h2 className="product-heading">Pharmaceutical Products</h2>
          <p className="base-paragraph product-paragraph">
            Himont's Pharmaceutical Division offers top-quality medicines in
            various categories such as digestion, nutrition, allergy, cough,
            cold and pain relief.
          </p>
          <p className="base-paragraph product-paragraph hide-paragraph">
            Our products are developed through extensive research, complying
            with international quality and safety standards. We manufacture
            medicines for all age groups and diseases with a cohesive approach,
            utilizing available resources efficiently to treat diseases in a
            cost-effective manner.
          </p>
          <div
            onClick={() => {
              setSeeMore(true);
              setParagraphOne(
                `Himont's Pharmaceutical Division offers top-quality medicines in various categories such as digestion, nutrition, allergy, cough, cold and pain relief.`
              );
              setParagraphTwo(
                `Our products are developed through extensive research, complying with international quality and safety standards. We manufacture medicines for all age groups and diseases with a cohesive approach, utilizing available resources efficiently to treat diseases in a cost-effective manner.`
              );
              setHeading(`Pharmaceutical Products`);
            }}
            className="product-see-more"
          >
            Read More
          </div>
          <button className="view-product-btn" onClick={openLink}>
            View Pharmaceutical Products
          </button>
        </div>
      </div>
    </>
  );
}

export default Product1;
