import React, { useEffect, useState } from "react";
import Footer from "../Common/Footer/Footer";
import Contact from "../Common/Contact/Contact";
import { useAllPrismicDocumentsByType } from "@prismicio/react";
import Follow from "../Media&Events/Follow";
import Loader from "../Common/Loader";

function ContactPage() {
  const [loader, setLoader] = useState(false);
  useEffect(() => {
    document.title = "Contact | Himont";
    setTimeout(() => {
      setLoader(true);
    }, 1500);
  }, [loader]);

  if (!loader) {
    return <Loader />;
  }
  return (
    <>
      <Contact />
      <Follow />
      <Footer />
    </>
  );
}

export default ContactPage;
