import { useEffect, useState } from "react";
import React from "react";
import images from "../../../Constants/images";
import JobRole from "./JobRole";

function Role1({ data }) {
  const [showNewComponent, setShowNewComponent] = useState(false);
  const [dataIndex, setDataIndex] = useState(false);

  const handleClick = () => {
    setShowNewComponent(true);
  };
  useEffect(() => {
    if (showNewComponent) {
      document.body.classList.add("no-scroll");
    } else {
      document.body.classList.remove("no-scroll");
    }
  }, [showNewComponent]);

  return (
    <>
      <div className="container">
        {data?.map((i, index) => (
          <div className="row" key={index}>
            <div
              className="col-lg-8 pointer"
              onClick={() => {
                handleClick();
                setDataIndex(index);
              }}
            >
              <div className="more-box padding-lr align-more">
                <h6 className="role-h1">{i?.data?.job_title[0]?.text}</h6>
                <svg
                  className="double-arrow"
                  width="36"
                  height="36"
                  viewBox="0 0 36 36"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g opacity="0.9">
                    <path
                      d="M4.5 31.5V19.5H7.5V26.4L26.4 7.5H19.5V4.5H31.5V16.5H28.5V9.6L9.6 28.5H16.5V31.5H4.5Z"
                      fill="#CB343B"
                    />
                  </g>
                </svg>
              </div>

              <div className="border-bottom"></div>
            </div>
            <div className="col-lg-4"></div>
          </div>
        ))}
      </div>
      <div
        className={
          showNewComponent
            ? "overlay-backdrop open-animation background"
            : "overlay-backdrop close-animation"
        }
      ></div>
      <div
        className={
          showNewComponent
            ? "overlay add-animation"
            : "overlay remove-animation "
        }
      >
        <JobRole
          data={data}
          dataIndex={dataIndex}
          setDataIndex={setDataIndex}
          setShowNewComponent={setShowNewComponent}
        />
      </div>
    </>
  );
}

export default Role1;
