import React from "react";
import Fade from "react-reveal";

import images from "../../../Constants/images";
function Product2({
  url,
  alt,
  setSeeMore,
  setParagraphOne,
  setParagraphTwo,
  setHeading,
}) {
  const openLink = () => {
    window.open(
      "https://himont.com/shop/product-category/herbal-products/",
      "_blank"
    );
  };
  return (
    <>
      <div className="col-lg-5 product-center">
        <div className="product-info-box herbal-border left-product-info">
          <h2 className="product-heading">Herbal Products</h2>
          <p className="base-paragraph product-paragraph">
            Himont Group has pioneered the application of high-quality
            allopathic manufacturing practices in the production of herbal
            medicines.
          </p>
          <p className="base-paragraph product-paragraph hide-paragraph">
            Over the past decade, we have expanded our herbal division through
            advanced research, expert teams, and top-notch production
            facilities. The key advantage of Himont Herbal Products is that they
            stand out as pharmaceutical-grade natural solutions, offering a safe
            and effective alternative to traditional medicines.
          </p>
          <div
            onClick={() => {
              setSeeMore(true);
              setParagraphOne(
                `Himont Group has pioneered the application of high-quality allopathic manufacturing practices in the production of herbal medicines.`
              );
              setParagraphTwo(
                `Over the past decade, we have expanded our herbal division through advanced research, expert teams, and top-notch production facilities. The key advantage of Himont Herbal Products is that they stand out as pharmaceutical-grade natural solutions, offering a safe and effective alternative to traditional medicines.`
              );
              setHeading(`Herbal Products`);
            }}
            className="product-see-more"
          >
            Read More
          </div>
          <button className="view-product-btn" onClick={openLink}>
            View Herbal Products
          </button>
        </div>
      </div>

      <div className="col-lg-7">
        <div className="product-section herbal-bg">
          <div className="circle-lg light-herbal-bg circle-lg-left"></div>
          <div className="circle-box light-herbal-bg">
            <img className="product-img" src={url} alt={alt} loading="lazy" />
          </div>

          <div className="circle-sm light-herbal-bg circle-sm-left"></div>
        </div>
      </div>
    </>
  );
}

export default Product2;
