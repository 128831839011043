import React from "react";
import images from "../../Constants/images";
import Paragraphs from "../Common/Headings/Paragraphs";
import PolicyCircle from "./PolicyCircle";
import Fade from "react-reveal";

function Policy({ home }) {
  return (
    <section className="policy-bg">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            {/* <h2 className="product-h1" style={{ marginTop: "102px" }}>
              Relations
            </h2> */}
            <Fade bottom>
              <h2 className="product-h2">
                CSR Policy <span className="dot">.</span>
              </h2>
              <Paragraphs
                nameOfClass="product-paragraph"
                paragraphText="We have a strong commitment to corporate responsibility and a straightforward philosophy of wanting to serve and give back to our community. We are thus at the forefront, extending our help to the medical community through donations for hospitals, universities, diagnostic camps, and the rehabilitation of natural disaster victims. Providing scholarships for students of pharmacy schools and internships for new graduates of pharmacy and chemistry has established Himont among the medical community as a responsible and professional partner. We now want to take up a major issue and help Pakistan ' stop stunting among our children’ due to malnutrition and anaemia. As the first step towards our goal, we have developed a patient education and lifestyle modification film, ‘Kahin Der Na Ho Jaye’. This educational film will raise awareness about this serious issue faced by our community."
              />
            </Fade>
          </div>
          <Fade bottom>
            <div className="col-lg-4">
              <PolicyCircle
                imageURL={home[0]?.data?.csr_1?.url}
                altText={home[0]?.data?.csr_1?.alt}
                heading="Scholarship"
                paragraph="Scholarships For Students Of Pharmacy Schools"
              />
            </div>
            <div className="col-lg-4">
              <PolicyCircle
                imageURL={home[0]?.data?.csr_2?.url}
                altText={home[0]?.data?.csr_2?.alt}
                heading="Internship"
                paragraph="Internships For The New Graduates Of Pharmacy And Chemistry."
              />
            </div>
            <div className="col-lg-4">
              <PolicyCircle
                imageURL={home[0]?.data?.csr_3?.url}
                altText={home[0]?.data?.csr_3?.alt}
                heading="Community Donations"
                paragraph="Donations to Hospitals in the Shape of Patient Beds and Medicines (1.0 million per year)."
              />
            </div>
          </Fade>
          <Fade bottom>
            <div className="col-lg-4">
              <PolicyCircle
                imageURL={home[0]?.data?.csr_4?.url}
                altText={home[0]?.data?.csr_4?.alt}
                heading="Earthquake"
                paragraph="Donation Of 6 Millions To Rehabilitate Earthquake Victims."
              />
            </div>
            <div className="col-lg-4">
              <PolicyCircle
                imageURL={home[0]?.data?.csr_5?.url}
                altText={home[0]?.data?.csr_5?.alt}
                heading="Diagnostic Camps"
                paragraph="Free Diagnostic Camps For Poor Patients At The Major Medical
              Institutes."
              />
            </div>
            <div className="col-lg-4">
              <PolicyCircle
                imageURL={home[0]?.data?.csr_6?.url}
                altText={home[0]?.data?.csr_6?.alt}
                heading="Donations"
                paragraph="Rs. 2.5 Million donation to universities every year"
              />
            </div>
          </Fade>
        </div>
      </div>
    </section>
  );
}

export default Policy;
