import React, { useEffect } from "react";
import images from "../../Constants/images";
import { useRef } from "react";
import Counter from "./Counter";
import Paragraphs from "../Common/Headings/Paragraphs";
import Fade from "react-reveal";

function History() {
  return (
    <section className="history-bg">
      <div className="container">
        <div className="row">
          <div className="col-lg-6">
            <div className="adjust-text">
              <Fade bottom>
                <h2 className="history-heading2">
                  Our History in <br /> Numbers.
                </h2>
              </Fade>

              <Paragraphs
                nameOfClass="history-para"
                paragraphText="Life is a health journey, a journey of discovering, eliminating
                and facing challenges. As a 30 years old research driven company
                committed to innovation, Himont has proven itself within the
                short span of twenty two years as a market leader with a broad
                portfolio of innovative and established medicine. The best way
                to maintain health is to preserve it and we at Himont are
                dedicated to providing the optimal solutions that not only help
                preserve but also enhance the state of well-being."
              />
            </div>
          </div>
          <div className="col-lg-6 ">
            <div className="align-arrow">
              <div className="vl"></div>
              <i className="arrow down"></i>
              <Counter
                count={30}
                text="Years of Experience"
                spanDiv={false}
                spanText=""
              />
              <Counter
                count={250}
                text="Employee"
                spanDiv={true}
                spanText="+"
              />
              <Counter count={7} text="Countries" spanDiv={false} spanText="" />
              <Counter
                count={18000}
                text="Pharmacies covered"
                spanDiv={true}
                spanText="+"
              />
              <Counter
                count={230}
                text="No of Products"
                spanDiv={true}
                spanText="+"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default History;
