import React from "react";
import Paragraphs from "../Common/Headings/Paragraphs";
import TopHeadingSection from "../Common/Headings/TopHeadingSection";
import TimelineBox from "./TimelineBox";

function Timeline() {
  return (
    <section className="timeline bg-gray600">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <TopHeadingSection
              subText="History"
              mainHeading="Our Timeline"
              description="After 29 years, Himont Group has established itself as a top-tier pharmaceutical and herbal company. As we have accumulated a vast pool of experience, we have now decided to diversify into the FMCG sector. We will use our pharmaceutical and herbal businesses to be the backbone of the group while foraying into the world of consumer goods."
            />
          </div>
        </div>
        <div className="timeline-spacing"></div>
      </div>
      <div className="timeline-center-date">
        <div className="timeline-founded">
          <Paragraphs nameOfClass="date" paragraphText="Founded in 1994" />
        </div>
      </div>
      <div className="timeline-border">
        <div className="container">
          <TimelineBox
            year="1994"
            side="left"
            title="Foundation of Himont"
            description="Initially launching with a modest range of 4 products, Himont has since experienced impressive growth, now boasting an extensive portfolio of over 230 items – and the expansion shows no signs of slowing down."
          />
          <TimelineBox
            year="1995"
            side="right"
            title="GMP Certifications"
            description="Himont Group of Pharmaceuticals, in 1995 obtained the
            highly acclaimed GMP (Good Manufacturing Practices)
            International certifications for top-notch quality and is
            updating the certifications till this date. The
            acquisition of these certifications reflects the
            unwavering commitment of Himont towards innovation, which
            has been an integral part of the company's ethos and will
            continue to be so in the future."
          />
          <TimelineBox
            year="1998"
            side="left"
            title="Launch of Spasfon"
            description="Our flagship Product- Spasfon contains the active
            ingredient phloroglucinol. It is used to treat various
            conditions related to muscle spasms and pain, such as
            menstrual cramps, digestive spasms, and urinary tract
            spasms. Phloroglucinol works by relaxing the smooth
            muscles in the body, which can help alleviate pain and
            discomfort. It is available in different forms, including
            tablets, capsules, and injections. Medication should
            always be done under the supervision of a healthcare
            professional."
          />
          <TimelineBox
            year="1999"
            side="right"
            title="Launch of Pregnovit"
            description="One of our Top Quality Products- Pregnovit is a brand of
            prenatal vitamins that are commonly prescribed to pregnant
            women. Prenatal vitamins usually contain a combination of
            vitamins and minerals, such as folic acid, iron, calcium,
            and vitamin D, that are important for fetal development
            and the health of the mother during pregnancy. It's
            important to note that while prenatal vitamins can be
            beneficial for most pregnant women, they must only be
            taken under the guidance of a healthcare professional."
          />
          <TimelineBox
            year="2005"
            side="left"
            title="Himont Start Exporting"
            description="Himont Group initially started as a local enterprise, but
            with its exceptional product quality and unwavering
            dedication to bringing innovation in the healthcare
            sector, the company has expanded its operations and
            successfully started exporting its products to 7 different
            countries across the globe."
          />
          <TimelineBox
            year="2009"
            side="right"
            title="Launch of Dr. Koff"
            description="Dr. Koff is our flagship product in Himont Herbal Products. Dr Koff Syrup helps to give temporary relief from stuffy nose, sinus, and ear symptoms caused by allergies, the common cold, dry cough, flu, or other breathing illnesses such as sinusitis and bronchitis. Do not use Dr. Koff without consulting the doctor."
          />
          <TimelineBox
            year="2012"
            side="left"
            title="Himont Acquired Analytical Atomic Absorption Equipment"
            description="The use of atomic absorption spectroscopy by Himont 
            is highly regulated, and the equipment must comply with various 
            regulatory requirements, such as Good Manufacturing Practice (GMP) 
            and United States Pharmacopoeia (USP) standards. The equipment is 
            also regularly calibrated and validated to ensure accurate and precise results.
            "
          />
          <TimelineBox
            year="2019"
            side="right"
            title="Launch Of Clotless SS in Covid-19"
            description="The Himont Group introduced a groundbreaking medication
            called Clotless SS, which proved to be highly effective in
            combating the COVID-19 pandemic and helped save numerous
            lives."
          />
        </div>
      </div>
    </section>
  );
}

export default Timeline;
