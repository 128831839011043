import React from "react";
import images from "../../Constants/images";
import Paragraphs from "../Common/Headings/Paragraphs";
import Fade from "react-reveal";
function Hero({ research }) {
  return (
    <section className="research-section">
      <div className="container">
        <Fade bottom>
          <div className="row">
            <div className="col-lg-6 c-main-center">
              <div style={{ position: "relative" }}>
                <h1 className="main-heading">Research & Development</h1>
                <svg
                  className="our-products-svg"
                  height="20"
                  viewBox="0 0 291 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    opacity="0.5"
                    d="M5 14.0378C81 8.37117 243.4 -0.46216 285 9.53784"
                    stroke="#CB343B"
                    strokeWidth="10"
                    strokeLinecap="round"
                  />
                </svg>
              </div>

              <Paragraphs
                nameOfClass="c-main-paragraph"
                paragraphText="At Himont, we believe in pushing the boundaries of what's possible
              in the world of medicine. That's why we've established a
              state-of-the-art R&D structure that fosters creativity and
              accelerates the development of innovative treatments across all
              our departments.
              "
              />
              <Paragraphs
                nameOfClass="c-main-paragraph"
                paragraphText="But we don't stop there. We're always looking to the future and
                exploring new technologies that can help us improve our products
                and provide even better outcomes for our patients. At Himont,
                we're committed to staying at the forefront of medical innovation
                and bringing the best possible treatments to people all around the
                world.
              "
              />
            </div>

            <div className="col-lg-6">
              <div className="research-box">
                <img
                  className="research-img"
                  src={research && research[0]?.data?.research_banner_gif?.url}
                  alt="Research Gifs"
                  loading="lazy"
                />
              </div>
            </div>
          </div>
        </Fade>
      </div>
    </section>
  );
}

export default Hero;
