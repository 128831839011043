import React from "react";
import TopHeadingSection from "../Common/Headings/TopHeadingSection";
import images from "../../Constants/images";
import { Fade } from "react-reveal";

function CompanyImage({}) {
  return (
    <section className="company-image-section">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <TopHeadingSection
              mainHeading="Company Plant Visuals"
              description="Teamwork is key to success, allowing employees to express themselves with openness and ease."
            />
          </div>
        </div>

        <div className="row about-video">
          <div className="col-lg-12">
            <iframe
              className="uplayer-iframe"
              src="https://www.youtube.com/embed/jOJtpdEyyxM"
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowFullScreen
            ></iframe>
          </div>
        </div>
      </div>
    </section>
  );
}

export default CompanyImage;
