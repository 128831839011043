import React from "react";
import images from "../../../Constants/images";
import Paragraphs from "../../Common/Headings/Paragraphs";
import Group1 from "./Group1";
import Group2 from "./Group2";
import { Fade } from "react-reveal";

function Group({ home }) {
  return (
    <>
      <section>
        <Fade bottom>
          <div className="container">
            <div className="row">
              <div className="col-lg-12 group-margin">
                <h1 className="team-h1">We love to work together!</h1>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12">
                <Paragraphs
                  nameOfClass="work-heading"
                  paragraphText="Welcome to Himont, a workplace committed to employee wellness
                through shared values and transparency while keeping Teamwork at
                the core our people as it is to key to success, allowing
                employees to express themselves with openness and ease. Our
                inclusive environment promotes open communication, empowering
                individuals to thrive and contribute to our ongoing success."
                />
                <Paragraphs
                  nameOfClass="work-heading"
                  paragraphText="Our Shared Value System unifies us, driving our collective
                effort towards common goals. Transparency cultivates trust,
                ensuring that achievements are recognized and applauded, while
                allowing team members to reach their full potential."
                />
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12 group-margin">
                <Group1 home={home} />
              </div>
              <div className="col-lg-12">
                <Paragraphs
                  nameOfClass="work-heading"
                  paragraphText="Open Communication lies at the heart of Himont. We foster an
                atmosphere where every voice matters, combining diverse ideas to
                accomplish shared objectives. Our Open-door Policy keeps
                leadership accessible, encouraging dialogue and addressing
                employee needs."
                />
                <Paragraphs
                  nameOfClass="work-heading"
                  paragraphText="Teamwork is essential to our culture, nurturing collaboration,
                and camaraderie as we reach new heights together. Discover
                Himont, where our devotion to employee wellness paves the way
                for a healthier, happier world."
                />
              </div>
            </div>
          </div>
        </Fade>
      </section>
    </>
  );
}

export default Group;
