import React from "react";
import images from "../../../Constants/images";
import FooterEnd from "./FooterEnd";
import FooterLinkOne from "./FooterLinkOne";
import FooterLinkThree from "./FooterLinkThree";
import FooterLinkTwo from "./FooterLinkTwo";
import FooterSocialMedia from "./FooterSocialMedia";
import FooterSocialMediaIcons from "./FooterSocialMediaIcons";

function Footer() {
  return (
    <section className="footer-bg">
      <div className="container">
        <div className="row">
          <FooterSocialMedia images={images} />
          <div className="col-lg-2"></div>
          <FooterLinkOne />
          <div className="col-lg-1"></div>
          <FooterLinkTwo />
          <div className="col-lg-1"></div>
          <FooterLinkThree />

          <FooterSocialMediaIcons />

          <div className="col-lg-12">
            <div className="horizontal-line"></div>
          </div>
          <FooterEnd />
        </div>
      </div>
    </section>
  );
}

export default Footer;
